import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpErrorResponse, HttpBackend, HttpUrlEncodingCodec } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ErrorService } from "../error/error.service";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  public isLogged: boolean = false;
  public progressBarValue: number = 0;
  public codec = new HttpUrlEncodingCodec();
  constructor(private http: HttpClient, private errorService: ErrorService, private handler: HttpBackend) {}

  setLoggedInValue(booleanValue: boolean) {
    this.isLogged = booleanValue;
  }

  ////////////////create a new item / record  { headers: headers }
  postItem(path: string, postItems: any) {
    path = environment.deviceAPIURL + path;
    return new Promise((resolve, reject) => {
      this.http.post(path, postItems, { reportProgress: true, observe: "events" }).subscribe({
        next: (responseItem) => {
          switch (responseItem.type) {
            case HttpEventType.Response: {
              if (responseItem.status == 200) {
                resolve(responseItem.body);
              } else {
                this.errorService.handleException(responseItem);
              }
            }
          }
        },
        error: (errorItem) => {
          this.errorService.handleException(errorItem);
        },
      });
    });
  }

  uploadFile(path: string = "", inputData: any) {
    path = environment.deviceAPIURL + path;
    return new Promise((resolve, reject) => {
      this.http
        .post(path, inputData, {
          reportProgress: true,
          observe: "events",
        })
        .subscribe(
          (responseItem: any) => {
            if (responseItem.type === HttpEventType.UploadProgress) {
              this.progressBarValue = Math.round((100 * responseItem.loaded) / responseItem.total);
            } else if (responseItem.type == HttpEventType.Response) {
              resolve(responseItem.body);
            }
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }
}
