<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">My Files</h4>

                    <div class="table-responsive">
                        <table class="table mb-0">

                            <thead class="thead-dark">
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Total Count/th>
                                    <th>Distinct Count</th>
                                    <th>Fil Ratio</th>
                                    <th>Created At</th>
                                    <th>Manage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Device Item</td>
                                    <td>23,238,383</td>
                                    <td>23,238,383</td>
                                    <td>83%</td>
                                    <td>23, Jun, 2023</td>
                                    <td class="text-right">
                                        <a href='javascript:;' title="Download the Output File"><span class="bx bx-cloud-download"></span></a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <a href='javascript:;' title="Download the Input File"><span class="bx bx-download"></span></a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>Key Item</td>
                                    <td>55,238,423</td>
                                    <td>55,238,423</td>
                                    <td>93%</td>
                                    <td>23, Jun, 2023</td>
                                    <td class="text-right">
                                        <a href='javascript:;' title="Download the Output File"><span class="bx bx-cloud-download"></span></a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <a href='javascript:;' title="Download the Input File"><span class="bx bx-download"></span></a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>Device Item</td>
                                    <td>23,238,383</td>
                                    <td>23,238,383</td>
                                    <td>83%</td>
                                    <td>23, Jun, 2023</td>
                                    <td class="text-right">
                                        <a href='javascript:;' title="Download the Output File"><span class="bx bx-cloud-download"></span></a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <a href='javascript:;' title="Download the Input File"><span class="bx bx-download"></span></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>