import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";

@Component({
  selector: "app-accountslist",
  templateUrl: "./accountslist.component.html",
  styleUrls: ["./accountslist.component.scss"],
})
export class AccountslistComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  accountList: any = [];
  rowsPerPage: number = 10;
  totalRecords: number = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "Account List";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.getAccountList();
  }
  getAccountList() {
    this.spinner.show("accountListFullPageLoader");
    this.accountList = [];
    let payloadItem: any = {
      reportType: "get_dt_account",
      createdBy: this.sharingService.activeLoggedUsername,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.accountList = responseItem.body.Result;
      } else {
        this.errorService.handleException(responseItem.message);
      }
      this.spinner.hide("accountListFullPageLoader");
    });
  }
  edit(item: any) {
    this.router.navigate(["/device-mgmt/accounts/create/" + item.ID]);
  }
}
