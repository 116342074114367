import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-segmentation-create",
  templateUrl: "./segmentation-create.component.html",
  styleUrls: ["./segmentation-create.component.scss"],
})
export class SegmentationCreateComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  countryList: any = [];
  stateList: any = [];
  cityList: any = [];
  zipList: any = [];
  extraAttributesList: any = [];
  selectedCountyCode: any = [];
  selectedStateCode: any = [];
  selectedCityCode: any = [];
  selectedZipCode: any = [];
  selectedExtraAttributes: any = [];
  selectedSubItem: any = "";

  selectedCountryCodeValue: any = [];
  primaryAttributeConstraint: any = "";
  extraAttributeConstraint: any = "";

  segmentFormCreate: UntypedFormGroup;
  submitted: boolean = false;
  segmentCountByConstraint: any = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private cdRef: ChangeDetectorRef,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "Create Segmentation";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.segmentFormCreation();
    this.getCountryList();
    this.getSegmentExtraAttributes("United States");
  }
  segmentFormCreationSubmit() {
    this.submitted = true;
    if (this.segmentFormCreate.valid) {
      this.spinner.show("segmentationfullpageloader");
      this.httpService.postItem("device-tracking", this.segmentFormCreate.value).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          this.toastService.showSuccess("Success", "Successfully Submitted!..");
          this.router.navigate(["/device-mgmt/segmentation/list"]);
        }
        this.spinner.hide("segmentationfullpageloader");
      });
    }
  }

  segmentFormCreation() {
    this.segmentFormCreate = this.formBuilder.group({
      reportType: ["create_dt_segment", Validators.required],
      segmentName: ["", Validators.required],
      attributes: [""],
      extraAttributes: [""],
      userId: [this.sharingService.activeLoggedUsername],
    });
  }
  get segmentFormControls() {
    return this.segmentFormCreate.controls;
  }
  getSegmentCount() {
    this.primaryAttributeConstraint = "";
    if (this.selectedCountyCode.length > 0) {
      if (this.primaryAttributeConstraint != "") this.primaryAttributeConstraint += " AND ";
      this.primaryAttributeConstraint += " country_code in ( ";
      this.selectedCountyCode.forEach((element: any) => {
        this.primaryAttributeConstraint += "'" + element + "'";
      });
      this.primaryAttributeConstraint += " ) ";
    }
    if (this.selectedStateCode.length > 0) {
      if (this.primaryAttributeConstraint != "") this.primaryAttributeConstraint += " AND ";
      let tempItem: any = "";
      this.selectedStateCode.forEach((element: any) => {
        if (tempItem != "") tempItem += ",";
        tempItem += "'" + element + "'";
      });
      this.primaryAttributeConstraint += " state in ( " + tempItem + " ) ";
    }
    if (this.selectedCityCode.length > 0) {
      if (this.primaryAttributeConstraint != "") this.primaryAttributeConstraint += " AND ";
      let tempItem: any = "";
      this.selectedCityCode.forEach((element: any) => {
        if (tempItem != "") tempItem += ",";
        tempItem += "'" + element + "'";
      });
      this.primaryAttributeConstraint += " city in ( " + tempItem + " ) ";
    }
    if (this.selectedZipCode.length > 0) {
      if (this.primaryAttributeConstraint != "") this.primaryAttributeConstraint += " AND ";
      let tempItem: any = "";
      this.selectedZipCode.forEach((element: any) => {
        if (tempItem != "") tempItem += ",";
        tempItem += "'" + element + "'";
      });
      this.primaryAttributeConstraint += " zip in ( " + tempItem + " ) ";
    }

    this.segmentFormCreate.patchValue({
      attributes: this.primaryAttributeConstraint,
    });
    this.getSegmentCountFind();
  }
  getUpdateValueInSegmentForm() {}
  getExtraAttrCoundFind() {
    setTimeout(() => {
      this.extraAttributeConstraint = "";
      this.extraAttributesList.forEach((element: any) => {
        if (element.subItems.length > 0) {
          let checkedSubItem: any = "";
          element.subItems.forEach((elementSubItem: any) => {
            if (!elementSubItem.checked) {
              if (checkedSubItem != "") checkedSubItem += ",";
              checkedSubItem += "'" + elementSubItem.value + "'";
            }
          });
          if (checkedSubItem != "") {
            if (this.extraAttributeConstraint != "") this.extraAttributeConstraint += " AND ";
            this.extraAttributeConstraint += element.NAME + " not in (" + checkedSubItem + ")";
          }
        }
      });
      this.segmentFormCreate.patchValue({
        extraAttributes: this.extraAttributeConstraint,
      });
      this.getSegmentCountFind();
    }, 1000);
  }
  getSegmentCountFind() {
    this.spinner.show("segmentationfullpageloader");
    let conditionString: any = "";
    if (this.primaryAttributeConstraint != "") {
      if (conditionString != "") conditionString += " AND ";
      conditionString += this.primaryAttributeConstraint;
    }
    if (this.extraAttributeConstraint != "") {
      if (conditionString != "") conditionString += " AND ";
      conditionString += this.extraAttributeConstraint;
    }
    let payloadItem: any = {
      reportType: "get_segment_attribute_count",
      conditionString: conditionString,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.segmentCountByConstraint = responseItem.body.Result[0].COUNT;
      }
      this.spinner.hide("segmentationfullpageloader");
    });
  }
  getCountPrimaryAttr(event: any = {}) {
    this.selectedZipCode = [];
    event.forEach((element: any) => {
      this.selectedZipCode.push(element.value);
    });
    this.getSegmentCount();
  }
  getZipList(event: any = {}) {
    const filteredPayload = {};
    this.selectedCityCode = [];
    event.forEach((element: any) => {
      this.selectedCityCode.push(element.value);
    });
    let payloadItem: any = {
      reportType: "get_segment_primary_attributes",
      countryCode: this.selectedCountyCode,
      state: this.selectedStateCode,
      city: this.selectedCityCode,
      fieldVal: "zip",
      userId: this.sharingService.activeLoggedUsername,
    };
    for (const key in payloadItem) {
      if (payloadItem.hasOwnProperty(key) && (Array.isArray(payloadItem[key]) ? payloadItem[key].length > 0 : payloadItem[key] !== "")) {
        filteredPayload[key] = payloadItem[key];
      }
    }
    this.zipList = [];
    this.httpService.postItem("device-tracking", filteredPayload).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result.length > 0) {
          responseItem.body.Result.forEach((element: any) => {
            if (element) {
              this.zipList = [...this.zipList, { name: element.ZIP, value: element.ZIP }];
            }
          });
          this.getSegmentCount();
        }
      }
    });
  }
  getCityList(event: any = {}) {
    const filteredPayload = {};
    this.selectedStateCode = [];
    event.forEach((element: any) => {
      this.selectedStateCode.push(element.value);
    });
    let payloadItem: any = {
      reportType: "get_segment_primary_attributes",
      countryCode: this.selectedCountyCode,
      state: this.selectedStateCode,
      fieldVal: "city",
      userId: this.sharingService.activeLoggedUsername,
    };
    for (const key in payloadItem) {
      if (payloadItem.hasOwnProperty(key) && (Array.isArray(payloadItem[key]) ? payloadItem[key].length > 0 : payloadItem[key] !== "")) {
        filteredPayload[key] = payloadItem[key];
      }
    }
    this.cityList = [];
    this.httpService.postItem("device-tracking", filteredPayload).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result.length > 0) {
          responseItem.body.Result.forEach((element: any) => {
            if (element) {
              this.cityList = [...this.cityList, { name: element.CITY, value: element.CITY }];
            }
          });
          this.getSegmentCount();
        }
      }
    });
  }
  getStateList(event: any = {}) {
    const filteredPayload = {};
    this.selectedStateCode = [];
    this.selectedCityCode = [];
    this.selectedCountyCode = [];
    event.forEach((element: any) => {
      this.selectedCountyCode.push(element.value);
    });
    let payloadItem: any = {
      reportType: "get_segment_primary_attributes",
      countryCode: this.selectedCountyCode,
      fieldVal: "state",
      userId: this.sharingService.activeLoggedUsername,
    };
    for (const key in payloadItem) {
      if (payloadItem.hasOwnProperty(key) && (Array.isArray(payloadItem[key]) ? payloadItem[key].length > 0 : payloadItem[key] !== "")) {
        filteredPayload[key] = payloadItem[key];
      }
    }
    this.stateList = [];
    this.httpService.postItem("device-tracking", filteredPayload).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result.length > 0) {
          responseItem.body.Result.forEach((element: any) => {
            if (element) {
              this.stateList = [...this.stateList, { name: element.STATE, value: element.STATE }];
            }
          });
          this.getSegmentCount();
        }
      }
    });
  }
  selectAll(selectedElement: any, selectedStaus: boolean) {
    this.extraAttributesList[selectedElement].checked = !selectedStaus;
    console.log("selectedStaus----", selectedStaus);
    console.log("selectedElement----", selectedElement);
    console.log("selected----", this.extraAttributesList[selectedElement]);
    console.log("selected--item--", this.extraAttributesList[selectedElement].checked);
    if (this.extraAttributesList[selectedElement].subItems.length > 0) {
      this.extraAttributesList[selectedElement].subItems.forEach((element: any) => {
        element.checked = this.extraAttributesList[selectedElement].checked;
      });
    }
    this.getExtraAttrCoundFind();
  }
  getCountryList() {
    this.selectedCountyCode = [];
    this.selectedStateCode = [];
    this.selectedCityCode = [];
    let payloadItem: any = {
      reportType: "get_segment_primary_attributes",
      fieldVal: "country_code",
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result.length > 0) {
          responseItem.body.Result.forEach((element: any) => {
            if (element) {
              this.countryList.push({ name: element.COUNTRY_CODE, value: element.COUNTRY_CODE });
            }
          });
        }
        this.selectedCountryCodeValue.push("USA");
        this.selectedCountyCode.push({ name: "USA", value: "USA" });
        this.getStateList(this.selectedCountyCode);
        this.getSegmentCount();
      }
    });
  }
  getSegmentExtraAttributes(countryName: any = "") {
    this.extraAttributesList = [];
    let payloadItem: any = {
      reportType: "get_segment_attributes",
      userId: this.sharingService.activeLoggedUsername,
    };
    if (countryName != "") payloadItem["geoLocation"] = countryName;
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result.length > 0) {
          responseItem.body.Result.forEach((element: any) => {
            if (element) {
              let foundObject: any = "";
              foundObject = this.extraAttributesList.find((item: any) => item.NAME === element.NAME);
              if (foundObject) {
                if (element.VALUE != "") {
                  foundObject["subItems"].push({ checked: true, value: element.VALUE });
                }
              } else {
                element["checked"] = true;
                element["subItems"] = [];
                element["subItems"].push({ checked: true, value: element.VALUE });
                delete element.VALUE;
                this.extraAttributesList.push(element);
              }
            }
          });
        }
        console.log("extraAttributesList-----", this.extraAttributesList);
      }
    });
  }
}
