import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  showSuccess(title: any, content: any) {
    this.toastr.success(content, title, {
      timeOut: 3000,
      closeButton: true,
      enableHtml: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-bottom-right",
    });
  }
  showError(title: any, content: any) {
    this.toastr.error(content, title, {
      timeOut: 3000,
      closeButton: true,
      enableHtml: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-bottom-right",
    });
  }
  showWarning(title: any, content: any) {
    this.toastr.warning(content, title, {
      timeOut: 3000,
      closeButton: true,
      enableHtml: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-bottom-right",
    });
  }
  showInfo(title: any, content: any) {
    this.toastr.info(content, title, {
      timeOut: 3000,
      closeButton: true,
      enableHtml: true,
      progressBar: true,
      progressAnimation: "decreasing",
      positionClass: "toast-bottom-right",
    });
  }
}
