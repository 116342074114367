import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-query-build-list",
  templateUrl: "./query-build-list.component.html",
  styleUrls: ["./query-build-list.component.scss"],
})
export class QueryBuildListComponent implements OnInit {
  @Input("fileList") fileList: any;
  constructor() {}

  ngOnInit(): void {}
}
