import { Component, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-input-list",
  templateUrl: "./input-list.component.html",
  styleUrls: ["./input-list.component.scss"],
})
export class InputListComponent implements OnInit {
  titleOfPage: any = "";
  setupType: any = "";
  defaultPageSize: any = 10;
  breadCrumbItems: Array<{}>;
  myFilesListItem: any = [];
  payloadAttribute: any = {};
  inputAttriuteItem: any = [];
  mappingForm: boolean = false;
  modalRef: NgbModalRef | null = null;
  fileMappingActionItemsList: any = ["queryBuild", "fileUpload"];

  rowsPerPage: number = 10;
  totalRecords: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private modalService: NgbModal,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "My Input File List";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.myfileList();
  }
  myfileList() {
    this.spinner.show("filelistloader");
    let payloadItem: any = {
      reportType: "get_dt_setup",
      userId: this.sharingService.activeLoggedUsername,
      orderBy: "id",
      sortBy: "desc",
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.myFilesListItem = responseItem.body.Result;
      }
      this.spinner.hide("filelistloader");
    });
  }
  mappingAction(item: any, exlargeModal) {
    this.getdtSetup(item.ID);
    this.payloadAttribute["id"] = item.ID;
    this.modalRef = this.modalService.open(exlargeModal, { size: "xl", centered: true });
  }
  closeMappingAction(): void {
    if (this.modalRef) {
      this.modalRef.dismiss();
    }
  }
  getdtSetup(primaryId: any) {
    let payloadItem: any = {
      reportType: "get_dt_setup",
      id: primaryId,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result[0].FIRST_LINE != "") {
          this.payloadAttribute["setup_name"] = responseItem.body.Result[0].SETUP_NAME;
          this.inputAttriuteItem = responseItem.body.Result[0].FIRST_LINE.split("|");
          this.mappingForm = true;
        } else {
          this.spinner.hide("fileupload");
          this.errorService.handleException("There is no record.");
        }
      } else {
        this.spinner.hide("fileupload");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
  cancelList(item: any) {
    this.spinner.show("filelistloader");
    let payloadItem: any = {
      reportType: "update_dt_setup",
      id : item,
      userId: this.sharingService.activeLoggedUsername,
      isDelete : "Y",
      status : "stopped",
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.myfileList();
      }
      this.spinner.hide("filelistloader");
    });

  }
}