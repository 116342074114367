// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  deviceAPIURL: "https://7raica7m28.execute-api.us-east-1.amazonaws.com/v1/",
  "x-api-key": "rvb5F20Egl1DuElc0BIz93xCoBRfJeTC3ERJdf6V",
  aInfoSystem: "Vm14YVlWWXdNVWRVYms1VFlrWndjVlZzWkZOV1ZsSlhWMjVrVjJKR2NFWlZiWFF3VmpKR05sVnFUbHBXVm5CMlZqQmFZV1JIUmtaYVJsWnBWMGRvU0ZaVldrWlBWa0pTVUZRd1BRPT0=",
  sInfoSystem:
    "Vm14U1QyUXlSbkpPVlZwUFYwWktjbFZyVlRGaU1WVjNXa1JTYTAxWFVsaFdNblJyVjBaYWNsWnFUbFZOVjJoeVZqQmFZV015VGtkaVJsWlVVMFZLYjFkVVNYaFVNVXB6V2toT2FWSnRVbkJaV0hCWFVrWmFXR05GT1dsTmJFWXpXa1JPZDJGVk1IbGhSa1pXWWtkU1ZGUnRlR0ZYUjA1R1ZHMW9WMkV6UWpGV1ZWcFNaREZDVWxCVU1EMD0=",
  bucketName: "BusinessWatch",
  folderName: "DeviceTracking/setup/",
  authReDirectionURL: "http://localhost:4200/",
  appTokenVariable: "appTokenDevice",
  appLoginInfo: "appLoginInfoDevice",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
