import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  profileList: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "My Profile";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.myProfileList();
  }
  myProfileList() {
    this.spinner.show("profilefullpageloader");
    this.profileList = JSON.parse(localStorage.getItem("userInfo"));
    this.spinner.hide("profilefullpageloader");
  }

  getDetailArray(payload: string): string {
    return payload.replace(/[\[\]"]/g, '');
  }

}
