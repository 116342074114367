import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { environment } from "@env/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";

@Component({
  selector: "app-mapping-attribute-item",
  templateUrl: "./mapping-attribute-item.component.html",
  styleUrls: ["./mapping-attribute-item.component.scss"],
})
export class MappingAttributeItemComponent {
  @Input("inputAttriuteItem") inputAttriuteItem: any;
  @Input("payloadAttribute") payloadAttribute: any;
  @Input("isPopup") isPopup: any;
  @Output() closeMappingAction: EventEmitter<void> = new EventEmitter<void>();
  metaAttriuteItem: any = [];
  metaAttriuteItemCopy: any = [];
  selectedRowItem: any = {};
  sourceSelectionItem: any = "";
  destinationItemName: any = "";
  destinationSelectionItem: any = {};
  mappingItemAttribute: any = [];
  mapAttributeForm: UntypedFormGroup;
  selectedAddressSubItem: any = "full_address";
  selectedDeviceSubItem: any = "ifa";
  inputAttributeSearchElement: any = "";
  systemAttributeSearchElement: any = "";
  metaAttributeItemList: any = [];
  selectedIndexItem: any = "";
  duplicateItems: any[] = [];
  paritialAddressValidation: boolean = true;
  //   public isPopup: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService, // public activeModal: NgbActiveModal,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    // console.log("inputAttriuteItem-----", this.inputAttriuteItem);
    // console.log("payloadAttribute-----", this.payloadAttribute);
    this.getMappingFormat();
  }
  changeDestinationItem(indexItem: any) {
    this.mappingItemAttribute.forEach((elementItem: any) => {
      elementItem.editMode = false;
    });
    this.selectedIndexItem = indexItem;
    this.mappingItemAttribute[indexItem]["editMode"] = true;
    this.metaAttributeItemList = [];

    let tempItem: any = {};
    tempItem["name"] = this.mappingItemAttribute[indexItem].source;
    tempItem["value"] = this.mappingItemAttribute[indexItem].source;
    tempItem["INPUT_META"] = [];
    this.metaAttributeItemList = [...this.metaAttributeItemList, tempItem];

    if (this.mappingItemAttribute[indexItem].source != this.mappingItemAttribute[indexItem].destination.name) {
      tempItem = {};
      tempItem["name"] = this.mappingItemAttribute[indexItem].destination.name;
      tempItem["value"] = this.mappingItemAttribute[indexItem].destination.name;
      if (this.mappingItemAttribute[indexItem].destination.INPUT_META.length == 0) tempItem["INPUT_META"] = [];
      else {
        tempItem["INPUT_META"] = this.mappingItemAttribute[indexItem].destination.INPUT_META;
        tempItem["ID"] = this.mappingItemAttribute[indexItem].destination.ID;
        tempItem["IS_ACTIVE"] = this.mappingItemAttribute[indexItem].destination.IS_ACTIVE;
      }
      this.metaAttributeItemList = [...this.metaAttributeItemList, tempItem];
    }
    this.metaAttriuteItemCopy.forEach((element: any) => {
      let tempItem: any = {};
      tempItem["name"] = element.ATTRIBUTE_NAME;
      tempItem["value"] = element.ATTRIBUTE_NAME;
      if (element.INPUT_META.length == 0) tempItem["INPUT_META"] = [];
      else {
        tempItem["INPUT_META"] = element.INPUT_META;
        tempItem["ID"] = element.ID;
        tempItem["IS_ACTIVE"] = element.IS_ACTIVE;
      }
      this.metaAttributeItemList = [...this.metaAttributeItemList, tempItem];
    });
    this.selectedRowItem["name"] = this.mappingItemAttribute[indexItem].destination.name;
    this.selectedRowItem["value"] = this.mappingItemAttribute[indexItem].source;
  }
  checkDeviceIdSelectOption(selectedValue: any = "") {
    this.selectedDeviceSubItem = selectedValue;
  }
  checkAddressSelectOption(selectedValue: any = "") {
    this.selectedAddressSubItem = selectedValue;
    if (this.selectedAddressSubItem != "" && this.selectedAddressSubItem == "partial") {
      this.toastService.showWarning("Warning!..", "You must be mapping Zip, City,State,Country");
      this.findParitialAddressValidation();
    }
  }
  findDuplicatesMappingAttribute() {
    this.duplicateItems = [];
    const destinationNamesSet = new Set();
    this.mappingItemAttribute.forEach((item: any) => {
      const name = item.destination.name;
      if (destinationNamesSet.has(name)) {
        this.duplicateItems.push(item);
      } else {
        destinationNamesSet.add(name);
      }
    });
    if (this.duplicateItems.length > 0) {
      this.toastService.showError("Error", "Duplicate Found!...");
    }
  }
  findParitialAddressValidation() {
    if (this.mappingItemAttribute.length > 0) {
      let isParitialAddress = 0;
      this.mappingItemAttribute.forEach((element: any) => {
        if (element.destination.name == "ADDRESS") {
          if (this.selectedAddressSubItem == "partial") {
            isParitialAddress = 1;
          }
        }
      });
      if (isParitialAddress) {
        let mappedItemActual: any = ["STATE", "CITY", "ZIP"];
        let copyMappedItem = [...mappedItemActual];
        this.mappingItemAttribute.forEach((element: any) => {
          const index = copyMappedItem.indexOf(element.destination.name);
          if (index !== -1) {
            copyMappedItem.splice(index, 1);
          }
        });
        if (copyMappedItem.length > 0) {
          this.paritialAddressValidation = false;
          let message = "";
          copyMappedItem.forEach((element: any) => {
            if (message != "") message += ",";
            message += element;
          });
          this.toastService.showError("Error", " Please must be mapping following this column list:<br/>" + message);
        }
      }
    }
  }
  updateDestinationItem(indexItem: any, event: any) {
    if (event) {
      const indexToAdd = this.metaAttriuteItem.findIndex((obj: any) => obj.ATTRIBUTE_NAME === this.mappingItemAttribute[indexItem]["destination"]["name"]);
      if (indexToAdd !== -1) {
        this.metaAttriuteItemCopy.push(this.metaAttriuteItem[indexToAdd]);
      }
      let tempItem: any = {};
      tempItem["name"] = event.value;
      if (event.INPUT_META.length == 0) {
        tempItem["INPUT_META"] = [];
        tempItem["IS_ACTIVE"] = "";
        tempItem["ID"] = 0;
      } else {
        tempItem["INPUT_META"] = event.INPUT_META;
        tempItem["IS_ACTIVE"] = event.IS_ACTIVE;
        tempItem["ID"] = event.ID;
      }
      this.mappingItemAttribute[indexItem]["destination"] = tempItem;
      this.mappingItemAttribute[indexItem]["editMode"] = false;
      const filteredArray = this.metaAttriuteItemCopy.filter((obj: any) => obj.ATTRIBUTE_NAME !== event.value);
      this.metaAttriuteItemCopy = [];
      this.metaAttriuteItemCopy = filteredArray;
      this.findDuplicatesMappingAttribute();
      this.findParitialAddressValidation();
    }
  }
  cancelSelection(indexItem: any) {
    this.mappingItemAttribute[indexItem]["editMode"] = false;
  }
  getMappingFormat() {
    this.inputAttriuteItem.forEach((element: any) => {
      this.mappingItemAttribute.push({ source: element, destination: { name: element, INPUT_META: [] }, editMode: false });
    });
    this.getMappingAttribute();
  }
  createNewOption(customText: any) {
    this.updateDestinationItem(this.selectedIndexItem, { name: customText, value: customText, INPUT_META: [] });
  }
  getMappingAttribute() {
    let payloadItem: any = {
      reportType: "get_dt_attribute_meta",
      userId: this.sharingService.activeLoggedUsername,
      is_active: "Y",
      orderBy: "attribute_name",
      sort_by: "asc",
    };
    this.spinner.show("mappingSubmission");
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      //   console.log("responseItem----", responseItem);
      if (responseItem.statusCode == "200") {
        this.spinner.hide("mappingSubmission");
        this.metaAttriuteItem = responseItem.body.Result;
        this.metaAttriuteItem.forEach((element: any) => {
          element.INPUT_META = JSON.parse(element.INPUT_META);
          let inputTempItem: any = [];
          if (element.INPUT_META.length > 0) {
            element.INPUT_META.forEach((ele: any) => {
              let checkedItem = false;
              if (ele === "full_address") checkedItem = true;
              inputTempItem.push({ item: ele, checked: checkedItem });
              //   ele.checked = false;
            });
          }
          element.INPUT_META = inputTempItem;
        });
        // console.log("metaAttriuteItem---", this.metaAttriuteItem);
        this.metaAttriuteItemCopy = [...this.metaAttriuteItem];
        if (this.mappingItemAttribute) {
          this.mappingItemAttribute.forEach((element: any) => {
            const indexToRemove = this.metaAttriuteItemCopy.findIndex((obj: any) => obj.ATTRIBUTE_NAME === element.destination.name);
            if (indexToRemove !== -1) {
              element["destination"]["ID"] = this.metaAttriuteItemCopy[indexToRemove]["ID"];
              element["destination"]["INPUT_META"] = this.metaAttriuteItemCopy[indexToRemove]["INPUT_META"];
              element["destination"]["IS_ACTIVE"] = this.metaAttriuteItemCopy[indexToRemove]["IS_ACTIVE"];
              this.metaAttriuteItemCopy.splice(indexToRemove, 1);
            }
          });
        }
      } else {
        this.spinner.hide("mappingSubmission");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
  mapAttributeFormSubmit() {
    // console.log("mappingItemAttribute-----", JSON.stringify(this.mappingItemAttribute));
    this.spinner.show("mappingSubmission");
    let mappingAttributeListPayload: any = [];
    if (this.mappingItemAttribute.length > 0) {
      this.paritialAddressValidation = true;
      let isParitialAddressMapping = 0;
      this.mappingItemAttribute.forEach((element: any) => {
        if (element) {
          let attributeListItem: any = {};
          attributeListItem["attribute_value"] = element.source;
          attributeListItem["mapping_attribute"] = element.destination.name;
          if (element.destination.INPUT_META.length > 0) {
            if (element.destination.name == "ADDRESS") {
              attributeListItem["mapping_attribute_id"] = element.destination.ID;
              attributeListItem["input_attribute_meta"] = [this.selectedAddressSubItem];
              if (this.selectedAddressSubItem == "partial") isParitialAddressMapping = 1;
            } else if (element.destination.name == "DEVICEID") {
              attributeListItem["mapping_attribute_id"] = element.destination.ID;
              attributeListItem["input_attribute_meta"] = [this.selectedDeviceSubItem];
            } else {
              attributeListItem["mapping_attribute_id"] = 0;
              attributeListItem["input_attribute_meta"] = [];
            }
          } else {
            attributeListItem["mapping_attribute_id"] = 0;
            attributeListItem["input_attribute_meta"] = [];
          }
          mappingAttributeListPayload.push(attributeListItem);
        }
      });
      if (mappingAttributeListPayload.length > 0) {
        if (isParitialAddressMapping) {
          let mappedItemActual: any = ["STATE", "CITY", "ZIP"];
          let copyMappedItem = [...mappedItemActual];
          mappingAttributeListPayload.forEach((element: any) => {
            const index = copyMappedItem.indexOf(element.mapping_attribute);
            if (index !== -1) {
              copyMappedItem.splice(index, 1);
            }
          });
          if (copyMappedItem.length > 0) {
            this.paritialAddressValidation = false;
            let message = "";
            copyMappedItem.forEach((element: any) => {
              if (message != "") message += ",";
              message += element;
            });
            this.toastService.showError("Error", " Please must be mapping following this column list:<br/>" + message);
          }
        }
        if (this.paritialAddressValidation) {
          let payloadItem: any = {
            reportType: "create_dt_attribute_map",
            setupName: this.payloadAttribute["setup_name"],
            setupId: this.payloadAttribute["id"],
            mappingAttributeList: mappingAttributeListPayload,
            userId: this.sharingService.activeLoggedUsername,
          };
          this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
            if (responseItem.statusCode == "200") {
              this.spinner.hide("mappingSubmission");
              // this.metaAttriuteItem = responseItem.body.Result;
              this.toastService.showSuccess("Success", "Successfully Submitted");

              if (this.isPopup) {
                this.closeMappingAction.emit();
              } else {
                this.router.navigate(["/device-mgmt/inputs/list"]);
              }
            } else {
              this.spinner.hide("mappingSubmission");
              this.errorService.handleException(responseItem.message);
            }
          });
        }
      }
    }
  }
}
