<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>
<div class="row">
    <div class="col-lg-12 tr-inputs">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title mb-4">Input Items</h4>
                <div class="col-lg-12 mt-2">
                    
                        <label class="d-block mb-2">Choose any one of the Input</label>
                        <div class="form-check form-check-inline" (click)="getInputOption(1);">
                            <input checked class="form-check-input" type="radio" name="inputUserOptions" id="inlineRadio1" value="1">
                            <label class="form-check-label" for="inlineRadio1">File Upload</label>
                        </div>
                        <div class="form-check form-check-inline" (click)="getInputOption(2);">
                            <input class="form-check-input" type="radio" name="inputUserOptions" id="inlineRadio2" value="2">
                            <label class="form-check-label" for="inlineRadio2">Segmentation</label>
                        </div>
                        <div class="form-check form-check-inline" (click)="getInputOption(3);">
                            <input class="form-check-input" type="radio" name="inputUserOptions" id="inlineRadio3" value="3">
                            <label class="form-check-label" for="inlineRadio3">Query</label>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-file-upload *ngIf="fileUploadSelector"></app-file-upload>
<app-segmentation-input *ngIf="segmentationSelector" [processType]="processType"></app-segmentation-input>
<app-query-input *ngIf="querySelector" [processType]="processType"></app-query-input>