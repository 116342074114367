<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <ngx-spinner name="mappingSubmission" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body">
                <h4 class="card-title">Mapping Attributes </h4>
                <!-- <form *ngIf="mapAttributeForm" [formGroup]="mapAttributeForm" (ngSubmit)="mapAttributeFormSubmit()"> -->
                <div class="row">
                    <div class="col-lg-12" *ngIf="mappingItemAttribute.length>0">
                        <div class="table-responsive">
                            <table class="table table-sm m-0">
                                <tbody>
                                    <tr>
                                        <th>Source</th>
                                        <th>Destination</th>
                                    </tr>
                                    <tr *ngFor="let item of mappingItemAttribute;let pI = index" [ngClass]="{'duplicateBorder': item.destination.name | duplicatefind:duplicateItems }">
                                        <td>{{item.source}}</td>
                                        <td *ngIf="!item.editMode"><a href="javascript:;" (click)="changeDestinationItem(pI)">{{item.destination.name}}</a>
                                            <div *ngIf="item.destination?.INPUT_META.length>0 && item.destination.name==='ADDRESS'">
                                                <div *ngFor="let tempItem of item.destination?.INPUT_META;let i = index">
                                                    <input [(ngModel)]="selectedAddressSubItem" type="radio" name="{{item.destination.name}}" id="{{item.destination.name}}" value="{{tempItem.item}}" (click)="checkAddressSelectOption(tempItem.item);">&nbsp;&nbsp;
                                                    {{ tempItem.item }}
                                                </div>
                                            </div>
                                            <div *ngIf="item.destination?.INPUT_META.length>0 && item.destination.name==='DEVICEID'">
                                                <div *ngFor="let tempItem of item.destination?.INPUT_META;let i = index">
                                                    <input [(ngModel)]="selectedDeviceSubItem" type="radio" name="{{item.destination.name}}" id="{{item.destination.name}}" value="{{tempItem.item}}" (click)="checkDeviceIdSelectOption(tempItem.item);">&nbsp;&nbsp;
                                                    {{ tempItem.item }}
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngIf="item.editMode">
                                            <span *ngIf="metaAttributeItemList.length>0">
                                                <ng-select [multiple]="false" [(ngModel)]="selectedRowItem" placeholder="Select Item" [items]="metaAttributeItemList" bindLabel="name" (change)="updateDestinationItem(pI,$event);" addTagText="Create New" [addTag]="createNewOption.bind(this)">
                                                </ng-select>
                                                <a href="javascript:;" (click)="cancelSelection(pI)">Cancel</a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style="float:right" class="col-lg-12 text-right" *ngIf="mappingItemAttribute.length>0 && duplicateItems.length==0 && paritialAddressValidation">
                                                <button type="click" class="btn btn-primary w-md" (click)="mapAttributeFormSubmit()">Submit</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>