<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header"></div>
                <div class="card-body">
                    <div class="inner">
                        <div style="font-size: 18px;letter-spacing: .5px;margin-bottom: 10px;">Siva</div>
                        <div style="font-size: 13px;letter-spacing: .5px;margin-bottom: 10px;">siva@sciera.com</div>
                        <div class="color__gray" style="font-size: 13px;letter-spacing: .5px;">CEO</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<p>Dashboard works</p>