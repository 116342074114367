import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SharingService {
  public activeLoggedUsername: any = "";
  public activeDisplayname: any = "";
  constructor(private route: ActivatedRoute, private router: Router, private msalService: MsalService) {}
  logout() {
    this.msalService.logout();
    this.router.navigate(["/account/login"]);
  }
}
