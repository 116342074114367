<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <ngx-spinner name="mappingSubmission" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body">
                <h4 class="card-title">Mapping Attributes </h4>
                <!-- <form *ngIf="mapAttributeForm" [formGroup]="mapAttributeForm" (ngSubmit)="mapAttributeFormSubmit()"> -->
                <div class="row">
                    <div class="col-lg-4">
                        <h4 class="card-title">Input Attributes</h4>
                        <div class="table-responsive">
                            <table class="table table-sm m-0" *ngIf="inputAttriuteItem.length>0">
                                <tbody>
                                    <tr height="25">
                                        <td><input type="text" name="inputAttributeSearchElement" id="inputAttributeSearchElement" [(ngModel)]="inputAttributeSearchElement" class="form-control" type="text" placeholder="Search Term"></td>
                                    </tr>
                                    <tr *ngFor="let item of inputAttriuteItem | attributeSearch:[inputAttributeSearchElement]" [class.table-info]="sourceSelectionItem===item">
                                        <td><a href="javascript:;" (click)="sourceItem(item)">{{item}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                            <table class="table table-sm m-0" *ngIf="inputAttriuteItem.length==0">
                                <tbody>
                                    <tr>
                                        <td class="text-center">There is no more Input columns</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <h4 class="card-title">Meta Attributes</h4>
                        <div class="table-responsive">
                            <table class="table table-sm m-0" *ngIf="( metaAttriuteItem | systemAttributeSearch : [systemAttributeSearchElement]) as systemAttributeList">
                                <tbody>
                                    <tr height="25">
                                        <td><input type="text" name="systemAttributeSearchElement" id="systemAttributeSearchElement" [(ngModel)]="systemAttributeSearchElement" class="form-control" type="text" placeholder="Search Term | Add Custom Item"></td>
                                    </tr>
                                    <tr height="25" *ngIf="systemAttributeList.length === 0">
                                        <td>
                                            <div class="input-group-append">
                                                <a href='javascript:;' (click)="customSystemAttributeMapping();"><span class="input-group-text bg-white"><i class="fe fe-sliders"></i>Add
                                                        Maping Data</span></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let item of systemAttributeList | systemAttributeSearch:[systemAttributeSearchElement]" [class.table-info]="destinationSelectionItem.ID===item.ID">
                                        <td><a href="javascript:;" (click)="destinationItem(item)">{{item.ATTRIBUTE_NAME}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- {{ mappingItemAttribute | json }} -->
                    <div class="col-lg-4" *ngIf="mappingItemAttribute.length>0">
                        <h4 class="card-title">Mapping Attributes</h4>
                        <div class="table-responsive">
                            <table class="table table-sm m-0">
                                <tbody>
                                    <tr>
                                        <th>Source</th>
                                        <th>Destination</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr *ngFor="let item of mappingItemAttribute;let pI = index">
                                        <td>{{item.attribute_value}}</td>
                                        <td>{{item.mapping_attribute}}
                                            <div *ngIf="item.mapping_item_data.INPUT_META.length>0 && item.mapping_attribute==='ADDRESS'">
                                                <div *ngFor="let tempItem of item.mapping_item_data.INPUT_META;let i = index">
                                                    <input [(ngModel)]="selectedAddressSubItem" type="radio" value="{{tempItem.item}}">&nbsp;&nbsp;
                                                    {{ tempItem.item }}
                                                </div>
                                            </div>
                                            <div *ngIf="item.mapping_item_data.INPUT_META.length>0 && item.mapping_attribute!='ADDRESS'">
                                                <div *ngFor="let tempItem of item.mapping_item_data.INPUT_META;let i = index">
                                                    <input [(ngModel)]="tempItem.checked" type="checkbox" (change)="checkboxChange($event,i,pI)">&nbsp;&nbsp;
                                                    {{ tempItem.item }}
                                                </div>
                                            </div>
                                        </td>
                                        <td><a href="javascript:;" (click)="unMapItem(item,pI)">Unmap</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <button type="button" [disabled]="sourceSelectionItem==='' || destinationItemName===''" class="btn btn-primary w-md" (click)="mapItem();">Map</button>
                    </div>
                    <div style="float:right" class="col-lg-6 text-right" *ngIf="mappingItemAttribute.length>0">
                        <button type="click" class="btn btn-primary w-md" (click)="mapAttributeFormSubmit()">Submit</button>
                    </div>
                </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
</div>