import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressbarModule,ProgressbarConfig } from 'ngx-bootstrap/progressbar';
import { WidgetModule } from "../shared/widget/widget.module";
import { UIModule } from "../shared/ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { DevicepagesRoutingModule } from "./devicepages-routing.module";
import { StarterComponent } from "./starter/starter.component";
import { InputItemsComponent } from "./input-items/input-items.component";
import { FileUploadComponent } from "./input-items/file-upload/file-upload.component";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { NgSelectModule } from "@ng-select/ng-select";
import { SegmentationInputComponent } from "./input-items/segmentation-input/segmentation-input.component";
import { QueryInputComponent } from "./input-items/query-input/query-input.component";
import { FilesListComponent } from "./files-list/files-list.component";
import { HttpService } from "./services/http/http.service";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { SegmentationCreateComponent } from "./segmentation/segmentation-create/segmentation-create.component";
import { SegmentationListComponent } from "./segmentation/segmentation-list/segmentation-list.component";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { ServiceListComponent } from "./service-list/service-list.component";
import { QueryBuildListComponent } from "./service-list/query-build-list/query-build-list.component";
import { SegmentListComponent } from "./service-list/segment-list/segment-list.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AccountscreateComponent } from "./accountscreate/accountscreate.component";
import { AccountslistComponent } from "./accountslist/accountslist.component";
import { UsercreateComponent } from "./usercreate/usercreate.component";
import { UserlistComponent } from "./userlist/userlist.component";
import { PagemetaaccessComponent } from "./pagemetaaccess/pagemetaaccess.component";
import { InputListComponent } from "./input-list/input-list.component";
import { MappingAttributeComponent } from "./input-items/mapping-attribute/mapping-attribute.component";
import { ReportCreateComponent } from "./report-create/report-create.component";
import { ReportListComponent } from "./report-list/report-list.component";
import { AttributeSearchPipe } from "./pipe/attribute-search.pipe";
import { SystemAttributeSearchPipe } from "./pipe/system-attribute-search.pipe";
import { ReportpathvalidationPipe } from "./pipe/reportpathvalidation.pipe";
import { NgbPaginationModule, NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { AgGridModule } from "ag-grid-angular";
import { TableModule } from "primeng/table";
import { PaginatorModule } from "primeng/paginator";
import { MappingAttributeItemComponent } from "./input-list/mapping-attribute-item/mapping-attribute-item.component";
import { DuplicatefindPipe } from "./pipe/duplicatefind.pipe";
import { PageaccessPipe } from './pipe/pageaccess.pipe';
import { AccessdeniedComponent } from './accessdenied/accessdenied.component';
import { ProfileComponent } from './profile/profile.component';
import { ProgresssystemComponent } from './shared/component/progresssystem/progresssystem.component';
@NgModule({
  declarations: [
    StarterComponent,
    InputItemsComponent,
    FileUploadComponent,
    SegmentationInputComponent,
    QueryInputComponent,
    FilesListComponent,
    SegmentationCreateComponent,
    SegmentationListComponent,
    ServiceListComponent,
    QueryBuildListComponent,
    SegmentListComponent,
    DashboardComponent,
    AccountscreateComponent,
    AccountslistComponent,
    UsercreateComponent,
    UserlistComponent,
    PagemetaaccessComponent,
    InputListComponent,
    MappingAttributeComponent,
    ReportCreateComponent,
    ReportListComponent,
    AttributeSearchPipe,
    SystemAttributeSearchPipe,
    ReportpathvalidationPipe,
    MappingAttributeItemComponent,
    DuplicatefindPipe,
    PageaccessPipe,
    AccessdeniedComponent,
    ProfileComponent,
    ProgresssystemComponent,
  ],
  imports: [
    CommonModule,
    ProgressbarModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    DropzoneModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    UIModule,
    WidgetModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    AgGridModule,
    DevicepagesRoutingModule,
    TableModule,
    PaginatorModule,
  ],
  providers: [
    ProgressbarConfig,
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
})
export class DevicepagesModule {}
