<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<div class="row">
    <div class="col-lg-12 tr-section">
        <div class="card">
            <ngx-spinner name="segmentationfullpageloader" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body" *ngIf="segmentationList.length>0">
                <div class="table-responsive">
                    <p-table #segmentationListTable [value]="segmentationList" [paginator]="true" [rows]="rowsPerPage" [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]" dataKey="ID">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="SEGMENT_NAME">Segmentation Name <p-sortIcon field="SEGMENT_NAME"></p-sortIcon>
                                    <p-columnFilter type="text" field="SEGMENT_NAME" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="CREATED_DATE">Created At <p-sortIcon field="CREATED_DATE"></p-sortIcon>
                                    <p-columnFilter type="text" field="CREATED_DATE" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="STATUS">Status <p-sortIcon field="STATUS"></p-sortIcon>
                                    <p-columnFilter type="text" field="STATUS" display="menu"></p-columnFilter>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.SEGMENT_NAME }}</td>
                                <td>{{ item.CREATED_DATE | date:'mediumDate' }}</td>
                                <td>{{ item.STATUS }}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>