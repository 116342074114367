import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-starter",
  templateUrl: "./starter.component.html",
  styleUrls: ["./starter.component.scss"],
})

/**
 * Utility starter component
 */
export class StarterComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;

  constructor() {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Home" },
      { label: "Starter Page", active: true },
    ];
  }
}
