import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-progresssystem',
  templateUrl: './progresssystem.component.html',
  styleUrls: ['./progresssystem.component.scss']
})
export class ProgresssystemComponent {
    @Input() processedValue: any = 50;
    maxNumber: number = 100;
    styleNotation: any = "height:30px;";
}
