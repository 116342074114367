import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";

@Component({
  selector: "app-service-list",
  templateUrl: "./service-list.component.html",
  styleUrls: ["./service-list.component.scss"],
})
export class ServiceListComponent implements OnInit {
  titleOfPage: any = "";
  setupType: any = "";
  defaultPageSize: any = 10;
  breadCrumbItems: Array<{}>;
  myFilesListItem: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    const segments = this.activatedRoute.snapshot.url.map((segment) => segment.path);
    if (segments[1] == "file-upload") {
      this.titleOfPage = "File Uploaded List";
      this.setupType = "fileupload";
    } else if (segments[1] == "segmentation") {
      this.titleOfPage = "Segmentation List";
      this.setupType = "segment";
      this.myfileList("segment");
    } else if (segments[1] == "query-build") {
      this.titleOfPage = "Query Build List";
      this.setupType = "queryBuild";
      this.myfileList("queryBuild");
    }
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
  }

  myfileList(setupType: any) {
    this.spinner.show("filelistloader");
    let payloadItem: any = {
      reportType: "get_dt_setup",
      userId: this.sharingService.activeLoggedUsername,
      setupType: setupType,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.myFilesListItem = responseItem.body.Result;
      }
      this.spinner.hide("filelistloader");
    });
  }
}
