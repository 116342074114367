<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<ngx-spinner name="accountCreateFullPageLoader" size="large" type="ball-scale-multiple"
    [fullScreen]="true"></ngx-spinner>

<div class="row">
    <div class="col-12">
        <div class="card" *ngIf="accountFormCreate">
            <div class="card-body">
                <h4 class="card-title mb-4">{{ titleOfPage }}</h4>
                <div class="col-12">
                    <form [formGroup]="accountFormCreate" (ngSubmit)="accountFormCreationSubmit()">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-label">Account Name</label>
                                <input formControlName="accountName" class="form-control" type="text"
                                    placeholder="Account Name"
                                    [ngClass]="{'is-invalid': submitted && accountFormControls.accountName.errors}">
                                <div *ngIf="submitted && accountFormControls.accountName.errors"
                                    class="invalid-feedback">
                                    <span *ngIf="accountFormControls.accountName.errors.required">Please provide a valid
                                        Account Name.</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Domain Name</label>
                                <input formControlName="domainName" class="form-control" type="text"
                                    placeholder="Domain Name"
                                    [ngClass]="{'is-invalid': submitted && accountFormControls.domainName.errors}">
                                <div *ngIf="submitted && accountFormControls.domainName.errors"
                                    class="invalid-feedback">
                                    <span *ngIf="accountFormControls.domainName.errors.required">Please provide a valid
                                        Domain Name.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mt-2 mb-2">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="isActive"
                                    formControlName="isActive">
                                <label class="form-check-label" for="floatingCheck">
                                    is Active
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 d-flex justify-content-end">
                                <button type="submit" class="btn btn-primary">
                                    <i class="bx bx-check me-2"></i>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>