import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "systemAttributeSearch",
})
export class SystemAttributeSearchPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let searchItemText = args[0];
    if (searchItemText != "") {
      let filteredItem = value.filter((valItem: any) => {
        return valItem.ATTRIBUTE_NAME.toString().toLowerCase().indexOf(searchItemText.toString().toLowerCase()) !== -1;
      });
      return filteredItem;
    } else {
      return value;
    }
  }
}
