<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<div class="row">
    <div class="col-lg-12 tr-section">
        <div class="card">
            <ngx-spinner name="reportPageLoader" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body" *ngIf="reportList.length>0">
                <h4 class="card-title">
                    <span style="float: right;padding-right:20px;"><a href='javascript:;' (click)="myReportList();">Reload</a></span>
                </h4>
                <br />
                <div class="table-responsive">
                    <p-table #reportListTable [value]="reportList" [paginator]="true" [rows]="rowsPerPage" [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]" dataKey="ID">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="REPORT_NAME">Report Name <p-sortIcon field="REPORT_NAME"></p-sortIcon>
                                    <p-columnFilter type="text" field="REPORT_NAME" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="PROCESS_TYPE">Report Type <p-sortIcon field="PROCESS_TYPE"></p-sortIcon>
                                    <p-columnFilter type="text" field="PROCESS_TYPE" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="REPORT_PATH">Total Count <p-sortIcon field="REPORT_PATH"></p-sortIcon>
                                    <p-columnFilter type="text" field="REPORT_PATH" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="REPORT_PATH">Output Count <p-sortIcon field="REPORT_PATH"></p-sortIcon>
                                    <p-columnFilter type="text" field="REPORT_PATH" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="CREATED_DATE">Created At <p-sortIcon field="CREATED_DATE"></p-sortIcon>
                                    <p-columnFilter type="text" field="CREATED_DATE" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="STATUS">Status <p-sortIcon field="STATUS"></p-sortIcon>
                                    <p-columnFilter type="text" field="STATUS" display="menu"></p-columnFilter>
                                </th>
                                <th>Download</th>
                                <th>Preview</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.REPORT_NAME }}</td>
                                <td>{{ item.PROCESS_TYPE }}</td>
                                <td>{{ getValues(item.REPORT_PATH)?.Total_Count | number }}</td>
                                <td>{{ getValues(item.REPORT_PATH)?.Output_Count| number}}</td>
                                <td>{{ item.CREATED_DATE | date:'mediumDate' }}</td>
                                <td>{{ item.STATUS }}</td>
                                <td>

                                    <span *ngIf="(item | reportpathvalidation: item.REPORT_PATH)['status'] && (item | reportpathvalidation: item.REPORT_PATH)['count']>0">
                                        <a href="javascript:;" (click)="downloadReportFile(item);">Download</a>
                                    </span>
                                    <span *ngIf="(item | reportpathvalidation: item.REPORT_PATH)['status']==false && (item | reportpathvalidation: item.REPORT_PATH)['count']==0">
                                        No Records
                                    </span>
                                    <span *ngIf="(item | reportpathvalidation: item.REPORT_PATH)['status']==false && item.STATUS!='stopped' && !item.ERROR_STRING">
                                        <a href="javascript:;" (click)="cancelList(item.ID);">Cancel</a>
                                    </span>
                                </td>
                                <td>
                                    <button type="button" *ngIf="(item | reportpathvalidation: item.REPORT_PATH)['status'] && (item | reportpathvalidation: item.REPORT_PATH)['count']>0" class="btn btn-primary" (click)="previewAction(item,exlargeModal);">
                                        <i class="bx bx-file"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #exlargeModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title mt-0">Preview</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')">
        </button>
    </div>
    <div class="modal-body">
        <table class="table">
            <!-- <thead>
                <tr>
                    <th>Column 1</th>
                </tr>
            </thead> -->
            <tbody>
                <tr *ngFor="let item of fileValue">
                    <td *ngFor="let subItem of item.split(',')">{{ subItem }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>