<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <ngx-spinner name="segmentationsubmission" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body">
                <h4 class="card-title mb-4">Segmentation</h4>
                <form *ngIf="segmentationForm" [formGroup]="segmentationForm" (ngSubmit)="segmentationFormSubmit()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label class="form-label">Display Name</label>
                                <input formControlName="setupName" class="form-control" type="text" placeholder="Display Name" [ngClass]="{'is-invalid': submitted && segmentControl.setupName.errors}">
                                <div *ngIf="submitted && segmentControl.setupName.errors" class="invalid-feedback">
                                    <span *ngIf="segmentControl.setupName.errors.required">Please provide a valid Display Name.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="segmentation-input">Segmentation</label>
                                <ng-select formControlName="segmentId" [multiple]="false" placeholder="Select Segmentation" [items]="segmentationList" bindLabel="SEGMENT_NAME" bindValue="ID" [ngClass]="{'is-invalid': submitted && segmentControl.segmentId.errors}">
                                </ng-select>
                                <div *ngIf="submitted && segmentControl.segmentId.errors" class="invalid-feedback">
                                    <span *ngIf="segmentControl.segmentId.errors.required">Please select a valid Segmentation.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="submit" class="btn btn-primary w-md">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>