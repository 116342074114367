import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 111,
    label: "Dashboard",
    icon: "bx bxs-star",
    link: "/device-mgmt/dashboard",
    userType: "user",
    accessLabel: "DASHBOARD",
  },
  {
    id: 1,
    label: "Inputs",
    icon: "bx-home-circle",
    userType: "user",
    subItems: [
      {
        id: 2,
        label: "Create",
        link: "/device-mgmt/inputs/create",
        parentId: 1,
        accessLabel: "INPUTSCREATE",
      },
      {
        id: 3,
        label: "List",
        link: "/device-mgmt/inputs/list",
        parentId: 1,
        accessLabel: "INPUTSLIST",
      },
    ],
  },
  {
    id: 1,
    label: "Accounts",
    icon: "bx-home-circle",
    userType: "admin",
    subItems: [
      {
        id: 2,
        label: "Create",
        link: "/device-mgmt/accounts/create",
        parentId: 1,
      },
      {
        id: 3,
        label: "List",
        link: "/device-mgmt/accounts/list",
        parentId: 1,
      },
    ],
  },
  {
    id: 1,
    label: "User",
    icon: "bx-home-circle",
    userType: "admin",
    subItems: [
      {
        id: 2,
        label: "Create",
        link: "/device-mgmt/user/create",
        parentId: 1,
      },
      {
        id: 3,
        label: "List",
        link: "/device-mgmt/user/list",
        parentId: 1,
      },
    ],
  },
  {
    id: 8,
    label: "Segmentation",
    userType: "user",
    icon: "bx bxs-star",
    subItems: [
      {
        id: 9,
        label: "Create",
        link: "/device-mgmt/segmentation/create",
        parentId: 8,
        accessLabel: "SEGMENTATIONCREATE",
      },
      {
        id: 10,
        label: "list",
        link: "/device-mgmt/segmentation/list",
        parentId: 8,
        accessLabel: "SEGMENTATIONLIST",
      },
    ],
  },
  {
    id: 9,
    label: "Report",
    userType: "user",
    icon: "bx bxs-star",
    subItems: [
      {
        id: 9,
        label: "Create",
        link: "/device-mgmt/report/create",
        parentId: 8,
        accessLabel: "REPORTCREATE",
      },
      {
        id: 10,
        label: "list",
        link: "/device-mgmt/report/list",
        parentId: 8,
        accessLabel: "REPORTLIST",
      },
    ],
  },
  {
    id: 15,
    label: "Page Meta Access",
    icon: "bx bxs-star",
    link: "/device-mgmt/page-meta-access",
    userType: "admin",
  },
  //   {
  //     id: 15,
  //     label: "Logout",
  //     icon: "bx bxs-star",
  //     link: "/logout",
  //     userType: "admin",
  //   },
];
