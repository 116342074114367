<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<ngx-spinner name="userListFullPageLoader" size="large" type="ball-scale-multiple" [fullScreen]="true"></ngx-spinner>


<div class="row">
    <div class="col-lg-12 tr-section">
        <div class="card">
            <div class="card-body" *ngIf="userList.length>0">



                <p-table #userListTable [value]="userList" [paginator]="true" [rows]="rowsPerPage" [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]" dataKey="ID">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="USER_EMAIL">User Email<p-sortIcon field="USER_EMAIL"></p-sortIcon>
                                <p-columnFilter type="text" field="USER_EMAIL" display="menu"></p-columnFilter>
                            </th>
                            <th pSortableColumn="ACCESS_TYPE">Access Type<p-sortIcon field="ACCESS_TYPE"></p-sortIcon>
                                <p-columnFilter type="text" field="ACCESS_TYPE" display="menu"></p-columnFilter>
                            </th>
                            <th pSortableColumn="ACCOUNT_NAME">Account Name<p-sortIcon field="ACCOUNT_NAME"></p-sortIcon>
                                <p-columnFilter type="text" field="ACCOUNT_NAME" display="menu"></p-columnFilter>
                            </th>
                            <th pSortableColumn="DOMAIN_NAME">Domain Name<p-sortIcon field="DOMAIN_NAME"></p-sortIcon>
                                <p-columnFilter type="text" field="DOMAIN_NAME" display="menu"></p-columnFilter>
                            </th>
                            <th pSortableColumn="ACTIVE">Active<p-sortIcon field="ACTIVE"></p-sortIcon>
                                <p-columnFilter type="text" field="ACTIVE" display="menu"></p-columnFilter>
                            </th>
                            <th pSortableColumn="LAST_UPDATED_DATE">Update At<p-sortIcon field="LAST_UPDATED_DATE"></p-sortIcon>
                                <p-columnFilter type="text" field="LAST_UPDATED_DATE" display="menu"></p-columnFilter>
                            </th>
                            <th pSortableColumn="CREATED_BY">Created By<p-sortIcon field="CREATED_BY"></p-sortIcon>
                                <p-columnFilter type="text" field="CREATED_BY" display="menu"></p-columnFilter>
                            </th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ item.USER_EMAIL }}</td>
                            <td>{{ item.ACCESS_TYPE }}</td>
                            <td>{{ item.ACCOUNT_NAME }}</td>
                            <td>{{ item.DOMAIN_NAME }}</td>
                            <td><span class="badge bg-primary rounded-pill tr-active">{{ item.ACTIVE=="Y" ? "Active" : "De-Active" }}</span></td>
                            <td>{{ item.LAST_UPDATED_DATE | date:'mediumDate' }}</td>
                            <td>{{ item.CREATED_BY }}</td>
                            <td class="tr-table-icon"><button type="button"  (click)="edit(item);" class="btn btn-outline-primary me-2"><i class="bx bx-edit-alt"></i></button> <button type="button" class="btn btn-outline-primary"><i class="bx bx-show"></i></button></td>
                        </tr>
                    </ng-template>
                </p-table>

            </div>
        </div>
    </div>
</div>