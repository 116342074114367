<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<ngx-spinner name="segmentationfullpageloader" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
<div class="row mb-4">
    <div class="col-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title mb-3">GEO Information</h4>
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 mb-3" *ngIf="countryList.length>0">
                            <label for="formrow-inputCountry">Country</label>
                            <ng-select [multiple]="true" placeholder="Select Country" [items]="countryList" bindLabel="name" bindValue="value" (change)="getStateList($event);" [(ngModel)]="selectedCountryCodeValue">
                            </ng-select>
                        </div>
                        <div class="col-lg-6 mb-3" *ngIf="stateList.length>0">
                            <label for="formrow-inputState">State</label>
                            <ng-select [multiple]="true" placeholder="Select State" [items]="stateList" bindLabel="name" bindValue="value" (change)="getCityList($event);">
                            </ng-select>
                        </div>
                        <div class="col-lg-6 mb-3" *ngIf="cityList.length>0">
                            <label for="formrow-inputState">City</label>
                            <ng-select [multiple]="true" placeholder="Select City" [items]="cityList" bindLabel="name" bindValue="value" (change)="getZipList($event);">
                            </ng-select>
                        </div>
                        <div class="col-lg-6 mb-3" *ngIf="zipList.length>0">
                            <label for="formrow-inputState">Zip1</label>
                            <ng-select [multiple]="true" placeholder="Select Zip" [items]="zipList" bindLabel="name" bindValue="value" (change)="getCountPrimaryAttr($event);">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6 tr-segmentation">
        <div class="card" *ngIf="segmentFormCreate">
            <div class="card-body">
                <h4 class="card-title mb-3">Segmentation</h4>
                <div class="col-12">
                    <div class="row">
                        <form [formGroup]="segmentFormCreate" (ngSubmit)="segmentFormCreationSubmit()">
                            <div class="col-lg-12">
                                <label class="form-label">Setup Name</label>
                                <input formControlName="segmentName" class="form-control" type="text" placeholder="Setup Name" [ngClass]="{'is-invalid': submitted && segmentFormControls.segmentName.errors}">
                                <div *ngIf="submitted && segmentFormControls.segmentName.errors" class="invalid-feedback">
                                    <span *ngIf="segmentFormControls.segmentName.errors.required">Please provide a valid Setup Name.</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 d-flex justify-content-between mt-2 align-items-end tr-seg-count">
                                    <div><span class="badge bg-primary rounded-pill">{{ segmentCountByConstraint | number
                                            }}</span></div>
                                    <button type="submit" class="btn btn-primary"><i class="bx bx-check me-2"></i>Submit</button>

                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" *ngIf="extraAttributesList.length>0">
    <div class="col-12 tr-attributes">
        <div class="row">
            <div class="col-12 ">
                <div class="card p-0">
                    <div class="card-body tr-extra-attributes">
                        <h4 class="card-title mb-3">Extra Attributes</h4>
                        <div class="card-columns">
                            <div class="card attributes-card" *ngFor="let item of extraAttributesList;let i = index">
                                <div class="card-header">
                                    <h6>{{ item.NAME }}
                                        <span style="float:right;">
                                            <input [(ngModel)]="item.checked" class="form-check-input" type="checkbox" (click)="selectAll(i,item.checked);">
                                            <label class="form-check-label" for="formCheck1">
                                                &nbsp; Select / De Select
                                            </label></span>
                                    </h6>
                                </div>
                                <div class="card-body">
                                    <div class="mail-list" *ngIf="item.subItems.length>0">
                                        <div class="form-check mb-0" *ngFor="let itemSub of item.subItems">
                                            <input [(ngModel)]="itemSub.checked" class="form-check-input" type="checkbox" (click)="getExtraAttrCoundFind();" value="itemSub.checked">
                                            <label class="form-check-label" for="formCheck1">
                                                {{ (itemSub.value!="" ? itemSub.value : "Unknown") }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <input (click)="getSelectedExtraAttributeItem(i)" [(ngModel)]="item.checked" value="item.NAME" class="form-check-input" type="checkbox" id="item.ID" name="item.ID">
                                    <label class="form-check-label" for="formCheck1">
                                    {{ item.NAME }}
                                    </label> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>