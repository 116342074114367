import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: "app-input-items",
  templateUrl: "./input-items.component.html",
  styleUrls: ["./input-items.component.scss"],
})
export class InputItemsComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  titleOfPage: any = "";
  processType: any = "";
  fileUploadSelector: boolean = false;
  segmentationSelector: boolean = false;
  querySelector: boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.processType = "";
    this.titleOfPage = "Inputs";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    const segments = this.activatedRoute.snapshot.url.map((segment) => segment.path);
    this.getInputOption(1);
  }
  getInputOption(inputOption: number = 0) {
    if (inputOption == 1) {
      this.fileUploadSelector = true;
      this.segmentationSelector = false;
      this.querySelector = false;
    }
    if (inputOption == 2) {
      this.fileUploadSelector = false;
      this.segmentationSelector = true;
      this.querySelector = false;
    }
    if (inputOption == 3) {
      this.fileUploadSelector = false;
      this.segmentationSelector = false;
      this.querySelector = true;
    }
  }
}
