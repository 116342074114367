import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "reportpathvalidation",
})
export class ReportpathvalidationPipe implements PipeTransform {
  transform(itemInput: any, ...args: any[]): any {
    try {
      let reportPath: any = args[0];
      if (reportPath != "") {
        var reportPathParser: any = JSON.parse(reportPath);
        if (itemInput.STATUS == "Completed" && reportPathParser[0].Total_Count > 0) {
          return { status: true, count: reportPathParser[0].Total_Count };
        } else {
          return { status: false, count: reportPathParser[0].Total_Count };
        }
      } else {
        return { status: false };
      }
    } catch (error) {
      return { status: false };
    }
  }
}
