import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-pagemetaaccess",
  templateUrl: "./pagemetaaccess.component.html",
  styleUrls: ["./pagemetaaccess.component.scss"],
})
export class PagemetaaccessComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  metaList: any = [];
  submitted: boolean = false;
  pageMetaFormCreate: UntypedFormGroup;
  rowsPerPage: number = 10;
  totalRecords: number = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "Page Meta List";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.getPageMetaList();
    this.pageMetaFormCreation();
  }
  getPageMetaList() {
    this.spinner.show("pageMetList");
    this.metaList = [];
    let payloadItem: any = {
      reportType: "get_page_meta",
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.metaList = responseItem.body.Result;
      } else {
        this.errorService.handleException(responseItem.message);
      }
      this.spinner.hide("pageMetList");
    });
  }
  edit(item: any) {
    this.pageMetaFormCreate.patchValue({
      reportType: "update_page_meta",
      id: item.ID,
      pageName: item.PAGE_NAME,
    });
  }
  pageMetaFormCreationSubmit() {
    this.submitted = true;
    if (this.pageMetaFormCreate.valid) {
      this.spinner.show("pageMetCreate");
      //   this.pageMetaFormCreate.value.isActive = this.pageMetaFormCreate.value.isActive ? "Y" : "N";
      this.httpService.postItem("device-tracking", this.pageMetaFormCreate.value).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          const message = this.pageMetaFormCreate.value.id > 0 ? "Successfully Updated!..." : "Successfully Created!...";
          this.toastService.showSuccess("Success", message);
          this.getPageMetaList();
          this.submitted = false;
          this.pageMetaFormCreate.patchValue({
            reportType: "create_page_meta",
            id: "",
            pageName: "",
          });
        } else {
          this.errorService.handleException(responseItem.message);
        }
        this.spinner.hide("pageMetCreate");
      });
    }
  }
  pageMetaFormCreation(item: any = {}) {
    this.pageMetaFormCreate = this.formBuilder.group({
      reportType: ["create_page_meta", Validators.required],
      id: [""],
      pageName: ["", Validators.required],
      userId: [this.sharingService.activeLoggedUsername, Validators.required],
      createdBy: [this.sharingService.activeLoggedUsername, Validators.required],
    });
  }
  get pageMetaFormControls() {
    return this.pageMetaFormCreate.controls;
  }
}
