import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
import { NgZone } from "@angular/core";

@Component({
  selector: "app-usercreate",
  templateUrl: "./usercreate.component.html",
  styleUrls: ["./usercreate.component.scss"],
})
export class UsercreateComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  userFormCreate: UntypedFormGroup;
  submitted: boolean = false;
  editUserId: number = 0;
  accountList: any = [];
  userList: any = [];
  metaList: any = [];
  userAccessList: any = [];
  isChecked: boolean = true;
  pageAccessScreen: boolean = false;
  accessType: any = [
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" },
  ];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService,
    private ngZone: NgZone
  ) {
    this.editUserId = 0;
    if (Object.keys(this.activatedRoute.snapshot.params).length > 0) {
      this.editUserId = Number(this.activatedRoute.snapshot.params["id"]);
    }
  }

  ngOnInit(): void {
    this.getAccountList();
    this.titleOfPage = "Create Account";
    if (this.editUserId > 0) {
      this.titleOfPage = "Edit Account";
      this.getuserList();
    } else {
      this.userFormCreation();
      this.getPageMetaAll();
    }
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
  }
  getuserList() {
    this.spinner.show("userCreateFullPageLoader");
    this.userList = [];
    let payloadItem: any = {
      reportType: "get_dt_user",
      userId: this.sharingService.activeLoggedUsername,
      id: this.editUserId,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if(responseItem.body.count>0){
        this.userList = responseItem.body.Result;
        this.userAccessList = JSON.parse(this.userList[0]["PAGE_ACCESS"]);
        this.userFormCreation(this.userList[0]);
        this.getPageMetaAll(this.userList[0]);
        } else {
          this.toastService.showError("Failed","No data");
        }
      } else {
        this.errorService.handleException(responseItem.message);
      }
      this.spinner.hide("userCreateFullPageLoader");
    });
  }
  userFormCreationSubmit() {
    this.submitted = true;
    let pageAccessArray: any = [];
    if (this.userFormCreate.value.accessType == "user") {
      this.metaList.forEach((element: any) => {
        if (element.checked) pageAccessArray.push(element.PAGE_NAME);
      });
    }
    this.userFormCreate.patchValue({
      pageAccess: pageAccessArray,
    });
    if (this.userFormCreate.valid) {
      this.spinner.show("userCreateFullPageLoader");
      this.userFormCreate.value.isActive = this.userFormCreate.value.isActive ? "Y" : "N";
      this.httpService.postItem("device-tracking", this.userFormCreate.value).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          const message = this.userFormCreate.value.id > 0 ? "Successfully Updated!..." : "Successfully Created!...";
          this.toastService.showSuccess("Success", message);
          this.router.navigate(["/device-mgmt/user/list"]);
        } else {
          this.errorService.handleException(responseItem.message);
        }
        this.spinner.hide("userCreateFullPageLoader");
      });
    }
  }
  userFormCreation(item: any = {}) {
    let selectedItem: any = {
      userEmail: "",
      accountId: "",
      isActive: false,
      id: 0,
      accessType: "",
      reportType: "create_dt_user",
    };
    if (Object.keys(item).length > 0) {
      selectedItem.reportType = "update_dt_user";
      selectedItem.userEmail = item.USER_EMAIL;
      selectedItem.accountId = item.ACCOUNT_ID;
      selectedItem.accessType = item.ACCESS_TYPE;
      selectedItem.isActive = item.ACTIVE == "Y" ? true : false;
      selectedItem.id = item.ID;
    }
    if (item.ACCESS_TYPE == "user") this.pageAccessScreen = true;
    else this.pageAccessScreen = false;
    this.userFormCreate = this.formBuilder.group({
      reportType: [selectedItem.reportType, Validators.required],
      id: [selectedItem.id],
      createdBy: [this.sharingService.activeLoggedUsername, Validators.required],
      userEmail: [selectedItem.userEmail, Validators.required],
      accountId: [selectedItem.accountId, Validators.required],
      pageAccess: [""],
      accessType: [selectedItem.accessType, Validators.required],
      isActive: [selectedItem.isActive, Validators.required],
      userId: [this.sharingService.activeLoggedUsername, Validators.required],
    });
  }
  get userFormControls() {
    return this.userFormCreate.controls;
  }
  getAccountList() {
    this.accountList = [];
    let payloadItem: any = {
      reportType: "get_dt_account",
      createdBy: this.sharingService.activeLoggedUsername,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.accountList = responseItem.body.Result;
      } else {
        this.errorService.handleException(responseItem.message);
      }
      this.spinner.hide("accountListFullPageLoader");
    });
  }
  getPageMetaAll(editItem: any = {}) {
    this.metaList = [];
    let payloadItem: any = {
      reportType: "get_page_meta",
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        let tempMetaList: any = responseItem.body.Result;
        tempMetaList.forEach((element: any) => {
          element["checked"] = false;
        });
        if (Object.keys(editItem).length > 0) {
          let userAccessList: any = ([] = JSON.parse(this.userList[0]["PAGE_ACCESS"]));
          tempMetaList.forEach((element: any) => {
            if (this.userAccessList.includes(element.PAGE_NAME)) {
              element["checked"] = true;
            } else {
              element["checked"] = false;
            }
          });
        }
        this.metaList = [] = [...tempMetaList];
      } else {
        this.errorService.handleException(responseItem.message);
      }
      //   this.spinner.hide("accountListFullPageLoader");
    });
  }
  getPageAccess(event: any) {
    if (event.value == "user") this.pageAccessScreen = true;
    else this.pageAccessScreen = false;
  }
}
