import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pageaccess",
})
export class PageaccessPipe implements PipeTransform {
  transform(value: any): boolean {
    return true;
    // let userAccessListStorage: any = JSON.parse(localStorage.getItem("userInfo"));
    // if (userAccessListStorage.ACCESS_TYPE == "user") {
    //   let userAccessList: any = [];
    //   if (userAccessListStorage) {
    //     userAccessList = JSON.parse(userAccessListStorage["PAGE_ACCESS"]);
    //   }
    // } else return true;
  }
}
