<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <ngx-spinner name="profilefullpageloader" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body">
                <div class="table-responsive">
                    <p-table #profileListTable [value]="[profileList]" [tableStyle]="{ 'min-width': '50rem' }">
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>Email Id</td>
                                <td>{{item['USER_EMAIL']}}</td>
                            </tr>
                            <tr>
                                <td>Role</td>
                                <td>{{item['ACCESS_TYPE']}}</td>
                            </tr>
                            <tr>
                                <td>Account Name</td>
                                <td>{{item['ACCOUNT_NAME']}}</td>
                            </tr>
                            <tr>
                                <td>Domain Name</td>
                                <td>{{item['DOMAIN_NAME']}}</td>
                            </tr>
                            <tr>
                                <td>Active Status</td>
                                <td>{{item['ACTIVE']}}</td>
                            </tr>
                            <tr>
                                <td>Created By</td>
                                <td>{{item['CREATED_BY']}}</td>
                            </tr>
                            <tr>
                                <td>Created Date</td>
                                <td>{{item['CREATED_DATE']}}</td>
                            </tr>
                            <tr *ngIf="item.ACCESS_TYPE=='user' && item.PAGE_ACCESS.length > 0 ">
                                <td>Page Access</td>
                                <td>{{getDetailArray(item.PAGE_ACCESS)}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>