import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import * as AWS from "aws-sdk";
import { environment } from "@env/environment";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-report-list",
  templateUrl: "./report-list.component.html",
  styleUrls: ["./report-list.component.scss"],
})
export class ReportListComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  reportList: any = [];
  rowsPerPage: number = 10;
  totalRecords: number = 0;
  modalRef: NgbModalRef | null = null;
  fileValue: any;
  private s3: AWS.S3;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.awsConfiguration();
    this.titleOfPage = "My Reports";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.myReportList();
  }
  awsConfiguration() {
    AWS.config.update({
      accessKeyId: atob(atob(atob(atob(atob(atob(environment.aInfoSystem)))))),
      secretAccessKey: atob(atob(atob(atob(atob(atob(environment.sInfoSystem)))))),
    });
    this.s3 = new AWS.S3();
  }
  downloadReportFile(item: any = {}) {
    let reportPath = JSON.parse(item.REPORT_PATH)[0];
    let key = reportPath.File_Path.split(environment.bucketName + "/")[1];
    const params = {
      Bucket: environment.bucketName,
      Key: key,
    };

    this.s3.getObject(params, (err, data) => {
      if (err) {
        this.toastService.showError("Error", "Error downloading the file: " + err);
      } else {
        const fileContent = data.Body; // The file content is available here
        console.log("data==", data.Body.toString());
        const fileName = key.split("/").pop(); // Extract the file name from the S3 object key

        // Trigger the file download
        this.downloadFile(fileContent, fileName);
      }
    });
  }
  downloadFile(fileContent: any, fileName: string) {
    const blob = new Blob([fileContent], { type: "application/octet-stream" });

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  myReportList() {
    this.spinner.show("reportPageLoader");
    let payloadItem: any = {
      reportType: "get_dt_report",
      userId: this.sharingService.activeLoggedUsername,
      orderBy: "id",
      sortBy: "desc",
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.reportList = responseItem.body.Result;
        this.totalRecords = responseItem.body.count;
      }
      this.spinner.hide("reportPageLoader");
    });
  }
  cancelList(item: any) {
    this.spinner.show("reportPageLoader");
    let payloadItem: any = {
      reportType: "update_dt_report",
      userId: this.sharingService.activeLoggedUsername,
      status: "stopped",
      id: item,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.myReportList();
        console.log("Successful");
      }
      this.spinner.hide("reportPageLoader");
    });
  }
  previewAction(item: any = {}, exlargeModal) {
    let reportPath = JSON.parse(item.REPORT_PATH)[0];
    let key = "/" + reportPath.File_Path.split(environment.bucketName + "/")[1];
    this.spinner.show("reportPageLoader");
    let payloadItem: any = {
      reportType: "get_dt_sample_records",
      userId: this.sharingService.activeLoggedUsername,
      setupFilePath: [key],
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      this.fileValue = responseItem;
      console.log("fileValue----", this.fileValue);
      this.fileValue.forEach((element: any) => {
        console.log("element----", element);
      });
      this.spinner.hide("reportPageLoader");
    });
    this.modalRef = this.modalService.open(exlargeModal, { size: "xl", centered: true });
  }

  getValues(data: any): any {
    if (!data) {
      return null;
    }
    try {
      data = JSON.parse(data);
      return data[0];
    } catch (error) {
      console.error("Error parsing data:", error);
      return null;
    }
  }
}
