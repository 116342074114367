<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<ngx-spinner name="reportCreationFormLoader" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
<div class="row">
    <div class="col-12">
        <div class="card" *ngIf="reportFormGenerate">
            <div class="card-body">
                <h4 class="card-title mb-4">Report Creation</h4>
                <div class="col-12">
                    <form [formGroup]="reportFormGenerate" (ngSubmit)="reportFormCreationSubmit()">
                        <div class="row">
                            <div class="col-lg-4 mb-3">
                                    <label class="form-label">Report Name</label>
                                    <input formControlName="reportName" class="form-control" type="text" placeholder="Report Name" [ngClass]="{'is-invalid': submitted && reportFormControl.reportName.errors}">
                                    <div *ngIf="submitted && reportFormControl.reportName.errors" class="invalid-feedback">
                                        <span *ngIf="reportFormControl.reportName.errors.required">Please provide a valid Report Name.</span>
                                    </div>
                            </div>
                            <div class="col-lg-4 mb-3" *ngIf="setupList.length>0">
                                    <label for="formrow-setupId">Select Setup</label>
                                    <ng-select [multiple]="false" placeholder="Select Setup" [items]="setupList" bindLabel="SETUP_NAME" bindValue="ID" (change)="getService($event);" formControlName="setupId">
                                    </ng-select>
                            </div>
                            <div class="col-lg-4 mb-3" *ngIf="processTypeList.length>0">

                                    <label for="formrow-processTypeList">Select Process Type</label>
                                    <ng-select [multiple]="false" placeholder="Select Setup" [items]="processTypeList" formControlName="processType">
                                    </ng-select>
                            </div>
                            <div class="col-lg-4 mb-3" *ngIf="reportFormGenerate.value.processType=='Shape'">
                                    <label for="formrow-thresholdVal">Threshold</label>
                                    <input formControlName="thresholdVal" class="form-control" type="text" placeholder="Threshold">
                            </div>
                            <div class="col-lg-12 d-flex justify-content-end">
                                <button type="submit" class="btn btn-primary"><i class="bx bx-check me-2"></i>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>