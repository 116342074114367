import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-files-list",
  templateUrl: "./files-list.component.html",
  styleUrls: ["./files-list.component.scss"],
})
export class FilesListComponent implements OnInit {
  titleOfPage: any = "";
  defaultPageSize: any = 10;
  breadCrumbItems: Array<{}>;
  queryBuildList: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "Files List";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.myQueryBuildList();
  }
  myQueryBuildList() {
    this.spinner.show("segmentationfullpageloader");
    let payloadItem: any = {
      reportType: "get_dt_setup",
      userId: this.sharingService.activeLoggedUsername,
      setupType: "segment",
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.queryBuildList = responseItem.body.Result;
      }
      this.spinner.hide("segmentationfullpageloader");
    });
  }
}
