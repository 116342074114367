<div class="row" *ngIf="fileUploadFormShow">
    <div class="col-lg-12">
        <ngx-spinner name="fileupload" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
        <div class="card">
            <div class="card-body">
                <h4 class="card-title mb-4">Upload File</h4>
                <div class="col-xs-12" *ngIf="selectedFiles.length>0">
                    <div  *ngFor="let item of selectedFiles">
                        <div>
                            <div>{{ item.aname }} </div>
                            <div><app-progresssystem  [processedValue]="item.progress"></app-progresssystem></div>
                        </div>
                    </div>
                </div> 
                <div *ngIf="selectedFiles.length==0">
                    <form *ngIf="fileUploadForm" [formGroup]="fileUploadForm" (ngSubmit)="fileSubmission()">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Display Name</label>
                                    <input formControlName="setupName" class="form-control" type="text" placeholder="Display Name" [ngClass]="{'is-invalid': submitted && fileUploadControl.setupName.errors}">
                                    <div *ngIf="submitted && fileUploadControl.setupName.errors" class="invalid-feedback">
                                        <span *ngIf="fileUploadControl.setupName.errors.required">Please provide a valid Display Name.</span>
                                    </div>
                                </div>
                            </div>
                            <!-- (change)="handleFileInput($event.target.files)" -->
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="uploadfile-input">Upload File</label>
                                    <input class="form-control" type="file" id="formFile" (change)="fileUploadValidation($event.target.files)" formControlName="fileItem" [ngClass]="{'is-invalid': submitted && fileUploadControl.fileItem.errors}" multiple="true">
                                    <div *ngIf="submitted && fileUploadControl.fileItem.errors" class="invalid-feedback">
                                        <span *ngIf="fileUploadControl.fileItem.errors.required">Please provide a valid upload file.</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6" *ngIf="((uploadFile && uploadFile.length>0) && (uploadFile.length==4 || uploadFile.length==5))">
                                <div class="mb-3">
                                    <label class="form-label">Threshold</label>
                                    <input formControlName="thresholdVal" class="form-control" type="text" placeholder="Threshold Value" [ngClass]="{'is-invalid': submitted && fileUploadControl.thresholdVal.errors}">
                                    <div *ngIf="submitted && fileUploadControl.thresholdVal.errors" class="invalid-feedback">
                                        <span *ngIf="fileUploadControl.thresholdVal.errors.required">Please provide a valid Threshold.</span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div>
                            <button type="submit" class="btn btn-primary w-md">Submit</button>
                        </div>
                    </form>
            </div>
            </div>
        </div>
    </div>
</div>
<br />
<app-mapping-attribute [inputAttriuteItem]="inputAttriuteItem" [payloadAttribute]="payloadAttribute" [isPopup]="false" *ngIf="!fileUploadFormShow"></app-mapping-attribute>