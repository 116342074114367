import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { environment } from "@env/environment";
import { NgxSpinnerService } from "ngx-spinner";
// import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-mapping-attribute",
  templateUrl: "./mapping-attribute.component.html",
  styleUrls: ["./mapping-attribute.component.scss"],
})
export class MappingAttributeComponent implements OnInit {
  @Input("inputAttriuteItem") inputAttriuteItem: any;
  @Input("payloadAttribute") payloadAttribute: any;
  @Input("isPopup") isPopup: any;
  @Output() closeMappingAction: EventEmitter<void> = new EventEmitter<void>();
  metaAttriuteItem: any = [];
  sourceSelectionItem: any = "";
  destinationItemName: any = "";
  destinationSelectionItem: any = {};
  mappingItemAttribute: any = [];
  mapAttributeForm: UntypedFormGroup;
  selectedAddressSubItem: any = "";
  inputAttributeSearchElement: any = "";
  systemAttributeSearchElement: any = "";
  //   public isPopup: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService, // public activeModal: NgbActiveModal,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.getMappingAttribute();
    this.mappingAttributeForm();
  }
  checkboxChange(event: any, index: any, parentIndex: any) {
    // this.mappingItemAttribute[parentIndex].mapping_item_data.INPUT_META[index].checked = !this.mappingItemAttribute[parentIndex].mapping_item_data.INPUT_META[index].checked;
    // console.log("checkboxChange---", event);
    // console.log("index---", index);
    // console.log("parentIndex---", parentIndex);
    // console.log("parentInmappingItemAttributedex---", JSON.stringify(this.mappingItemAttribute));
  }
  mapAttributeFormSubmit() {
    // return false;
    let isValidation: boolean = true;
    let mappedItemActual: any = ["STATE", "CITY", "ZIP"];
    this.mappingItemAttribute.forEach((element: any) => {
      if (element.mapping_attribute == "ADDRESS") {
        let isAddressSubItemCount = 0;
        let isPartialAddressMapping = 0;
        element.mapping_item_data.INPUT_META.forEach((ele: any) => {
          if (ele.item === this.selectedAddressSubItem) {
            isAddressSubItemCount = 1;
            if (this.selectedAddressSubItem === "partial") {
              isPartialAddressMapping = 1;
            }
          }
        });
        if (isAddressSubItemCount === 0) {
          this.toastService.showError("Error", "Atleast select any one of the subitem of Address");
          isValidation = false;
        } else {
          if (isPartialAddressMapping === 1) {
            let copyMappedItem = [...mappedItemActual];
            this.mappingItemAttribute.forEach((element: any) => {
              const index = copyMappedItem.indexOf(element.mapping_attribute);
              if (index !== -1) {
                copyMappedItem.splice(index, 1);
              }
            });
            if (copyMappedItem.length > 0) {
              let message = "";
              copyMappedItem.forEach((element: any) => {
                if (message != "") message += ",";
                message += element;
              });
              this.toastService.showError("Error", " Please must be mapping following this column list:<br/>" + message);
              isValidation = false;
            }
          }
        }
      }
    });
    if (this.inputAttriuteItem.length > 0) {
      this.toastService.showError("Error", "Please map all input columns");
      isValidation = false;
    }
    if (isValidation) {
      let mapAttribute: any = [];
      this.mappingItemAttribute.forEach((element: any) => {
        let tempItem: any = {};
        tempItem["attribute_value"] = element.attribute_value;
        tempItem["mapping_attribute"] = element.mapping_attribute;
        tempItem["mapping_attribute_id"] = element.mapping_attribute_id;
        if (element.mapping_item_data.INPUT_META.length > 0) {
          let subItem: any = [];
          if (element.mapping_attribute === "ADDRESS") {
            subItem.push(this.selectedAddressSubItem);
          } else if (element.mapping_attribute != "ADDRESS") {
            element.mapping_item_data.INPUT_META.forEach((eleItem: any) => {
              if (eleItem.checked) subItem.push(eleItem.item);
            });
          }
          tempItem["input_attribute_meta"] = subItem;
        } else {
          tempItem["input_attribute_meta"] = [];
        }
        mapAttribute.push(tempItem);
      });
      this.mapAttributeForm.patchValue({
        mappingAttributeList: mapAttribute,
      });
      if (this.mapAttributeForm.valid) {
        this.spinner.show("mappingSubmission");
        this.httpService.postItem("device-tracking", this.mapAttributeForm.value).then((responseItem: any) => {
          if (responseItem.statusCode == "200") {
            this.spinner.hide("mappingSubmission");
            // this.metaAttriuteItem = responseItem.body.Result;
            this.toastService.showSuccess("Success", "Successfully Submitted");

            if (this.isPopup) {
              this.closeMappingAction.emit();
            } else {
              this.router.navigate(["/device-mgmt/inputs/list"]);
            }
          } else {
            this.spinner.hide("mappingSubmission");
            this.errorService.handleException(responseItem.message);
          }
        });
      }
    }
  }
  //   status: ["mapped", Validators.required],
  mappingAttributeForm() {
    this.mapAttributeForm = this.formBuilder.group({
      reportType: ["create_dt_attribute_map", Validators.required],
      setupName: [this.payloadAttribute["setup_name"]],
      setupId: [this.payloadAttribute["id"], Validators.required],
      mappingAttributeList: ["", Validators.required],
      userId: [this.sharingService.activeLoggedUsername],
    });
  }

  get mapUploadControl() {
    return this.mapAttributeForm.controls;
  }

  customSystemAttributeMapping() {
    let item: any = {};
    item["ID"] = 0;
    item["ATTRIBUTE_NAME"] = this.systemAttributeSearchElement;
    item["INPUT_META"] = [];
    item["IS_ACTIVE"] = "Y";
    this.destinationItem(item).then((responseItem: any) => {
      if (responseItem) {
        this.systemAttributeSearchElement = "";
        this.inputAttributeSearchElement = "";
        if (this.sourceSelectionItem != "") {
          this.mapItem();
        } else {
          this.toastService.showWarning("Warning!..", "Please select Source Attribute");
        }
      }
    });
  }
  sourceItem(item: any = "") {
    this.sourceSelectionItem = item;
  }
  async destinationItem(item: any = ""): Promise<any> {
    this.destinationSelectionItem = item;
    this.destinationItemName = item.ATTRIBUTE_NAME;
    return true;
  }
  unMapItem(item: any, parentIndex: any) {
    this.inputAttriuteItem.push(this.mappingItemAttribute[parentIndex].attribute_value);
    this.mappingItemAttribute.splice(parentIndex, 1);
  }
  mapItem() {
    this.mappingItemAttribute.push({
      attribute_value: this.sourceSelectionItem,
      mapping_attribute: this.destinationSelectionItem.ATTRIBUTE_NAME,
      mapping_attribute_id: this.destinationSelectionItem.ID,
      mapping_item_data: this.destinationSelectionItem,
    });
    const index = this.inputAttriuteItem.indexOf(this.sourceSelectionItem);
    if (index !== -1) {
      this.inputAttriuteItem.splice(index, 1);
    }
    this.sourceSelectionItem = "";
    this.destinationSelectionItem = "";
    this.destinationItemName = "";
  }
  getMappingAttribute() {
    let payloadItem: any = {
      reportType: "get_dt_attribute_meta",
      userId: this.sharingService.activeLoggedUsername,
      is_active: "Y",
      orderBy: "attribute_name",
      sort_by: "asc",
    };
    this.spinner.show("mappingSubmission");
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.spinner.hide("mappingSubmission");
        this.metaAttriuteItem = responseItem.body.Result;
        this.metaAttriuteItem.forEach((element: any) => {
          element.INPUT_META = JSON.parse(element.INPUT_META);
          let inputTempItem: any = [];
          if (element.INPUT_META.length > 0) {
            element.INPUT_META.forEach((ele: any) => {
              let checkedItem = false;
              if (ele === "full_address") checkedItem = true;
              inputTempItem.push({ item: ele, checked: checkedItem });
              //   ele.checked = false;
            });
          }
          element.INPUT_META = inputTempItem;
        });
      } else {
        this.spinner.hide("mappingSubmission");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
}
