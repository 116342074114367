import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MicrosoftLoginGuard } from "./guards/microsoft-login.guard";
import { StarterComponent } from "./starter/starter.component";
import { InputItemsComponent } from "./input-items/input-items.component";
import { FilesListComponent } from "./files-list/files-list.component";
import { SegmentationCreateComponent } from "./segmentation/segmentation-create/segmentation-create.component";
import { SegmentationListComponent } from "./segmentation/segmentation-list/segmentation-list.component";
import { ServiceListComponent } from "./service-list/service-list.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AccountscreateComponent } from "./accountscreate/accountscreate.component";
import { AccountslistComponent } from "./accountslist/accountslist.component";
import { UsercreateComponent } from "./usercreate/usercreate.component";
import { UserlistComponent } from "./userlist/userlist.component";
import { PagemetaaccessComponent } from "./pagemetaaccess/pagemetaaccess.component";
import { InputListComponent } from "./input-list/input-list.component";
import { ReportCreateComponent } from "./report-create/report-create.component";
import { ReportListComponent } from "./report-list/report-list.component";
import { AccessdeniedComponent } from "./accessdenied/accessdenied.component";
import { ProfileComponent } from './profile/profile.component';
const routes: Routes = [
  {
    path: "starter",
    component: StarterComponent,
  },
  {
    path: "access-denied",
    component: AccessdeniedComponent,
  },
  {
    path: "dashboard",
    canActivate: [MicrosoftLoginGuard],
    component: DashboardComponent,
    data: { userType: "user", pageAccess: "DASHBOARD" },
  },
  {
    path: "page-meta-access",
    canActivate: [MicrosoftLoginGuard],
    component: PagemetaaccessComponent,
    data: { userType: "admin" },
  },
  {
    path: "accounts/create",
    canActivate: [MicrosoftLoginGuard],
    component: AccountscreateComponent,
    data: { userType: "admin" },
  },
  {
    path: "accounts/create/:id",
    canActivate: [MicrosoftLoginGuard],
    component: AccountscreateComponent,
    data: { userType: "admin" },
  },
  {
    path: "accounts/list",
    canActivate: [MicrosoftLoginGuard],
    component: AccountslistComponent,
    data: { userType: "admin" },
  },
  {
    path: "user/create",
    canActivate: [MicrosoftLoginGuard],
    component: UsercreateComponent,
    data: { userType: "admin" },
  },
  {
    path: "user/create/:id",
    canActivate: [MicrosoftLoginGuard],
    component: UsercreateComponent,
    data: { userType: "admin" },
  },
  {
    path: "user/list",
    canActivate: [MicrosoftLoginGuard],
    component: UserlistComponent,
    data: { userType: "admin" },
  },
  {
    path: "report/create",
    canActivate: [MicrosoftLoginGuard],
    component: ReportCreateComponent,
    data: { userType: "admin", pageAccess: "REPORTCREATE" },
  },
  {
    path: "report/list",
    canActivate: [MicrosoftLoginGuard],
    component: ReportListComponent,
    data: { userType: "admin", pageAccess: "REPORTLIST" },
  },
  {
    path: "inputs/create",
    canActivate: [MicrosoftLoginGuard],
    component: InputItemsComponent,
    data: { userType: "admin", pageAccess: "INPUTSCREATE" },
  },
  {
    path: "inputs/list",
    canActivate: [MicrosoftLoginGuard],
    component: InputListComponent,
    data: { userType: "admin", pageAccess: "INPUTSLIST" },
  },
  {
    path: "segmentation/create",
    canActivate: [MicrosoftLoginGuard],
    component: SegmentationCreateComponent,
    data: { userType: "admin", pageAccess: "SEGMENTATIONCREATE" },
  },
  {
    path: "segmentation/list",
    canActivate: [MicrosoftLoginGuard],
    component: SegmentationListComponent,
    data: { userType: "admin", pageAccess: "SEGMENTATIONLIST" },
  },
  {
    path: "profile",
    canActivate: [MicrosoftLoginGuard],
    component: ProfileComponent,
    data: { userType: "admin" },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DevicepagesRoutingModule {}
