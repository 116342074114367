<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">

                <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="../../../../assets/images/tracex-logo-dark.svg" alt="" height="30" class="auth-logo-dark">
                        <!-- <img src="assets/images/logo.svg" alt="" height="22"> -->
                    </span>
                    <span class="logo-lg">
                        <img src="../../../../assets/images/tracex-logo-dark.svg" alt="" height="30" class="auth-logo-dark">
                        <!-- <img src="assets/images/logo-dark.png" alt="" height="17"> -->
                    </span>
                </a>

                <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="../../../../assets/images/tracex-logo-dark.svg" alt="" height="30" class="auth-logo-dark">
                        <!-- <img src="assets/images/logo-light.svg" alt="" height="22"> -->
                    </span>
                    <span class="logo-lg">
                        <img src="../../../../assets/images/tracex-logo-dark.svg" alt="" height="30" class="auth-logo-dark">
                        <!-- <img src="assets/images/logo-light.png" alt="" height="19"> -->
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button>

        </div>

        <div class="d-flex">


            <div class="dropdown d-none d-lg-inline-block ms-1">
                <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
                    <i class="bx bx-fullscreen"></i>
                </button>
            </div>


            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
                    <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
                    <span class="d-none d-xl-inline-block ms-1">{{ loginUsername }}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- item-->
                    <a class="dropdown-item" routerLink="/device-mgmt/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
                        My Profile</a>
                    <a class="dropdown-item" routerLink="/device-mgmt/dashboard"><i class="bx bx-user font-size-16 align-middle me-1"></i>
                        My Dashboard</a>
                    <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                        Logout</a>
                </div>
            </div>

        </div>
    </div>
</header>