import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-segment-list",
  templateUrl: "./segment-list.component.html",
  styleUrls: ["./segment-list.component.scss"],
})
export class SegmentListComponent implements OnInit {
  @Input("fileList") fileList: any;

  constructor() {}

  ngOnInit(): void {}
}
