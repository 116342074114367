import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-segmentation-input",
  templateUrl: "./segmentation-input.component.html",
  styleUrls: ["./segmentation-input.component.scss"],
})
export class SegmentationInputComponent implements OnInit {
  @Input("processType") processType: any;
  segmentationForm: UntypedFormGroup;
  submitted: boolean = false;
  segmentationList: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.getSegmentationList();
  }
  getSegmentationList() {
    this.spinner.show("segmentationsubmission");
    let payloadItem: any = {
      reportType: "get_dt_segment",
      status: "completed",
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.segmentationList = responseItem.body.Result;
        this.spinner.hide("segmentationsubmission");
        this.segmentFormCreation();
      }
    });
  }
  segmentFormCreation() {
    this.segmentationForm = this.formBuilder.group({
      reportType: ["create_dt_setup", Validators.required],
      setupName: ["", Validators.required],
      setupType: ["segment"],
      segmentId: [, Validators.required],
      userId: [this.sharingService.activeLoggedUsername],
      processType: this.processType,
    });
  }

  get segmentControl() {
    return this.segmentationForm.controls;
  }

  segmentFormReset() {
    this.segmentationForm.patchValue({
      setupName: "",
      segmentId: "",
    });
  }
  segmentationFormSubmit() {
    this.submitted = true;
    if (this.segmentationForm.valid) {
      this.spinner.show("segmentationsubmission");
      let payloadItem: any = this.segmentationForm.value;
      this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          this.spinner.hide("segmentationsubmission");
          this.segmentFormReset();
          this.submitted = false;
          this.toastService.showSuccess("Success", "Successfully Submitted!...");
          this.router.navigate(["device-mgmt/inputs/list"]);
        } else {
          this.errorService.handleException(responseItem.errors);
        }
      });
    }
  }
}
