import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import * as AWS from "aws-sdk";
import { environment } from "@env/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
interface FileWithProgress extends File {
    progress: number;
  }
@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  files: any = {};
  fileUploadForm: UntypedFormGroup;
  submitted: boolean = false;
  fileUploadFormShow: boolean = true;
  payloadAttribute: any = {};
  inputAttriuteItem: any = [];
  uploadFile: FileList;
  selectedFiles: any[] = [];
//   selectedFiles: FileWithProgress[] = [];
  processedValue:any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {
    this.selectedFiles = [];
  }

  ngOnInit(): void {
    this.awsConfiguration();
    this.fileUploadFormCreation();
  }

  awsConfiguration() {
    AWS.config.update({
      accessKeyId: atob(atob(atob(atob(atob(atob(environment.aInfoSystem)))))),
      secretAccessKey: atob(atob(atob(atob(atob(atob(environment.sInfoSystem)))))),
    });
  }
  uploadFileToS3(files: FileList) {
    this.spinner.show("fileupload");
    const s3 = new AWS.S3();
    this.selectedFiles = Array.from(files) as FileWithProgress[];
    this.selectedFiles.forEach(file => {file.progress = 0,file.aname=""});
    // const timestamp = new Date().getTime().toString();
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

    const formattedTime = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}-${milliseconds}`;
    let isTotalCount = 0;
    let tempFileItemUpload: any = [];
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file = this.selectedFiles[i];
      const fileExtension = file.name.split(".").pop();
      const key = `${environment.folderName}${formattedTime}.${fileExtension}`;
      const params = {
        Bucket: environment.bucketName,
        Key: key,
        Body: file,
      };
      const upload = s3.upload(params);
      file.aname = file.name
      upload.on('httpUploadProgress', progress => {
        file.progress = Math.round((progress.loaded / progress.total) * 100);
        // this.processedValue[i]['processedValue'] = percentage;
        // console.log('Upload progress:', this.processedValue, '%');
      });

      upload.send((err, data) => {
        if (err) {
                  this.spinner.hide("fileupload");
                  // this.errorService.handleException(responseItem.message);
                  this.toastService.showError("Error", "Error uploading file:" + err);
        } else {
            tempFileItemUpload.push("/" + key);
                isTotalCount++;
                if (this.selectedFiles.length == isTotalCount) {
                  delete this.fileUploadForm.value.fileItem;
                  this.fileUploadForm.patchValue({
                    setupFilePath: tempFileItemUpload,
                  });
                  this.fileUploadFormSubmit();
                }
        }
      });
    //   s3.upload(params, (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
    //     if (err) {
    //       this.spinner.hide("fileupload");
    //       // this.errorService.handleException(responseItem.message);
    //       this.toastService.showError("Error", "Error uploading file:" + err);
    //     } else {
    //       tempFileItemUpload.push("/" + key);
    //     }
    //     isTotalCount++;
    //     if (files.length == isTotalCount) {
    //       delete this.fileUploadForm.value.fileItem;
    //       this.fileUploadForm.patchValue({
    //         setupFilePath: tempFileItemUpload,
    //       });
    //       this.fileUploadFormSubmit();
    //     }
    //   });
    }
    this.spinner.hide("fileupload");
  }

//   uploadFile(file: File): Promise<void> {
//     const params = {
//       Bucket: environment.awsBucketName,
//       Key: file.name,
//       Body: file
//     };

//     return new Promise((resolve, reject) => {
//       const upload = this.s3.upload(params);
//       upload.on('httpUploadProgress', progress => {
//         const percent = Math.round((progress.loaded / progress.total) * 100);
//         console.log('Upload progress:', percent, '%');
//       });

//       upload.send((err, data) => {
//         if (err) {
//           console.error('Upload error:', err);
//           reject(err);
//         } else {
//           console.log('Upload success:', data);
//           resolve();
//         }
//       });
//     });
//   }

  handleFileInput(files: FileList): void {
    if (files && files.length > 0) {
      //   this.uploadFile = files[0];
      //   console.log("files", file);
      //   this.uploadFileToS3(file);
    }
  }
  singleFileUploadExtensionValidation: any = ["csv", "txt", "xls", "doc"];
  fileUploadValidation(files: FileList | null) {
    const emptyFileList: FileList = new DataTransfer().files;
    this.uploadFile = emptyFileList;
    // this.fileUploadForm.patchValue({
    //   thresholdVal: 0,
    // });
    if (files && files.length > 0) {
      if (files.length === 1) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileExtension = file.name.split(".").pop();
          const index = this.singleFileUploadExtensionValidation.indexOf(fileExtension);
          if (index !== -1) {
            this.uploadFile = files;
          } else {
            this.toastService.showError("Error", "Invalid file uploaded!...Must be (csv,txt, xls,doc)");
          }
        }
      } else if (files.length === 4 || files.length === 5) {
        let isSHPFile = 0;
        let isDBFFile = 0;
        let isPRJFile = 0;
        let isSHXFile = 0;
        let isCPGFile = 0;
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileExtension = file.name.split(".").pop();

          if (fileExtension == "cpg") {
            isCPGFile++;
          }
          if (fileExtension == "shp") {
            isSHPFile++;
          }
          if (fileExtension == "dbf") {
            isDBFFile++;
          }
          if (fileExtension == "prj") {
            isPRJFile++;
          }
          if (fileExtension == "shx") {
            isSHXFile++;
          }
        }
        if (isPRJFile == 1 && isSHXFile == 1 && isSHPFile == 1 && isDBFFile == 1) {
          if (files.length === 4) {
            this.uploadFile = files;
          }
          if (files.length === 5) {
            if (isCPGFile == 1) {
              this.uploadFile = files;
            } else {
              this.toastService.showError("Error", "The fifth file is invalid format. Must be a .prj extension");
            }
          }
        } else {
          this.toastService.showError("Error", "Must be upload shape file(.prj,.shx,.shp and .dbf)");
        }
      } else {
        this.toastService.showError("Error", "Either upload single or four files");
      }
    } else {
      // Handle the case where no files are selected if necessary
    }
  }
  //   status: ["uploaded"],
  fileUploadFormCreation() {
    this.fileUploadForm = this.formBuilder.group({
      reportType: ["create_dt_setup", Validators.required],
      setupName: ["", Validators.required],
      setupType: ["fileUpload"],
      fileItem: ["", Validators.required],
      setupFilePath: [""],
      userId: [this.sharingService.activeLoggedUsername],
    });
  }

  get fileUploadControl() {
    return this.fileUploadForm.controls;
  }

  fileSubmission() {
    this.submitted = true;
    const file = this.fileUploadForm.get("setupFilePath").value;
    if (this.fileUploadForm.valid) {
      //   this.handleFileInput(file);
      //   this.
    //   this.uploadFile.forEach(file => (file.progress = 0));
      this.uploadFileToS3(this.uploadFile);
    }
  }

  fileUploadFormSubmit() {
    this.spinner.show("fileupload");
    this.submitted = true;
    let payloadItem: any = this.fileUploadForm.value;
    // return false;
    const fileList = this.fileUploadForm.get("setupFilePath").value;
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.toastService.showSuccess("Success", "Successfully Uploaded!....");
        // this.getdtSetup(responseItem.body.id);
        this.payloadAttribute["id"] = responseItem.body.id;
        this.router.navigate(["/device-mgmt/inputs/list"]);
      } else {
        this.spinner.hide("fileupload");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
  getdtSetup(primaryId: any) {
    let payloadItem: any = {
      reportType: "get_dt_setup",
      id: primaryId,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result[0].FIRST_LINE != "") {
          this.payloadAttribute["setup_name"] = responseItem.body.Result[0].SETUP_NAME;
          this.inputAttriuteItem = responseItem.body.Result[0].FIRST_LINE.split("|");
          this.fileUploadFormShow = false;
        } else {
          this.spinner.hide("fileupload");
          this.errorService.handleException("There is no record.");
        }
      } else {
        this.spinner.hide("fileupload");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
  onFileChange(event: any) {
    this.files = {};
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type == "text/csv" || file.type == "text/text") {
        // if (file.size <= this.uploadFileMaxSize) {
        this.files = file;
        let fileUploadPayload: any = {
          file_name: this.files.name,
          file_size: this.files.size,
          file_type: this.files.type,
          created_by: 1,
        };

        this.httpService.uploadFile("files", fileUploadPayload).then((responseItemUpload: any) => {
          if (responseItemUpload.status == "200") {
          }
        });
      } else {
        // this.toastService.showError("Error", "Invalid upload file type format...");
      }
    }
  }
}
