<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>

<ngx-spinner name="accountCreateFullPageLoader" size="large" type="ball-scale-multiple" [fullScreen]="true"></ngx-spinner>

<div class="row">
    <div class="col-lg-12">
        <div class="card" *ngIf="userFormCreate">
            <div class="card-body">
                <h4 class="card-title mb-3">{{ titleOfPage }}</h4>
                <div class="col-12">
                    <form [formGroup]="userFormCreate" (ngSubmit)="userFormCreationSubmit()">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="form-label">User Email</label>
                                <input formControlName="userEmail" class="form-control" type="text" placeholder="User Email" [ngClass]="{'is-invalid': submitted && userFormControls.userEmail.errors}">
                                <div *ngIf="submitted && userFormControls.userEmail.errors" class="invalid-feedback">
                                    <span *ngIf="userFormControls.userEmail.errors.required">Please provide a valid User Email.</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label class="form-label">Access Type</label>
                                <ng-select formControlName="accessType" [multiple]="false" placeholder="Select Access Type" [items]="accessType" bindLabel="label" bindValue="value" [ngClass]="{'is-invalid': submitted && userFormControls.accessType.errors}" (change)="getPageAccess($event)">
                                </ng-select>
                                <div *ngIf="submitted && userFormControls.accessType.errors" class="invalid-feedback">
                                    <span *ngIf="userFormControls.accessType.errors.required">Please provide a valid Select Access Type.</span>
                                </div>
                            </div>
                            <div class="col-md-4" *ngIf="accountList.length>0">
                                <label class="form-label">Account</label>
                                <ng-select formControlName="accountId" [multiple]="false" placeholder="Select Account" [items]="accountList" bindLabel="ACCOUNT_NAME" bindValue="ID" [ngClass]="{'is-invalid': submitted && userFormControls.accountId.errors}">
                                </ng-select>
                                <div *ngIf="submitted && userFormControls.accountId.errors" class="invalid-feedback">
                                    <span *ngIf="userFormControls.accountId.errors.required">Please provide a valid Select Account.</span>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-2 mb-2">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="isActive" formControlName="isActive">
                                    <label class="form-check-label" for="floatingCheck">
                                        is Active
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-12 d-flex justify-content-end">
                                <button type="submit" class="btn btn-primary"><i class="bx bx-check me-2"></i>Submit</button>
                            </div>
                        </div>
                    </form>
                    <div class="row" *ngIf="metaList.length>0 && pageAccessScreen">
                        <h4>PAGE ACCESS</h4>
                        <div class="col-md-3" *ngFor="let itemMeta of metaList">
                            <input type="checkbox" [(ngModel)]="itemMeta.checked">
                            <label class="form-check-label">
                                {{ itemMeta.PAGE_NAME }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>