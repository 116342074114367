<div class="container-fluid">
    <ngx-spinner name="filelistloader" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    <div class="row">
        <div class="col-lg-12 tr-inputs-list tr-section p-0">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12 d-flex justify-content-end mb-2">
                            <button type="button" (click)="myfileList();" class="btn btn-primary"><i
                                    class="bx bx-revision me-2"></i>Reload</button>
                        </div>
                    </div>

                    <div class="table-responsive" *ngIf="myFilesListItem.length>0">

                        <p-table #myFilesListTable [value]="myFilesListItem" [paginator]="true" [rows]="rowsPerPage"
                            [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [rowsPerPageOptions]="[10, 25, 50]" dataKey="ID">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="SETUP_NAME">Name<p-sortIcon field="SETUP_NAME"></p-sortIcon>
                                        <p-columnFilter type="text" field="SETUP_NAME" display="menu"></p-columnFilter>
                                    </th>
                                    <th pSortableColumn="CREATED_DATE">Created At<p-sortIcon
                                            field="CREATED_DATE"></p-sortIcon>
                                        <p-columnFilter type="text" field="CREATED_DATE"
                                            display="menu"></p-columnFilter>
                                    </th>
                                    <th pSortableColumn="SETUP_TYPE">Setup Type<p-sortIcon
                                            field="SETUP_TYPE"></p-sortIcon>
                                        <p-columnFilter type="text" field="SETUP_TYPE" display="menu"></p-columnFilter>
                                    </th>
                                    <th pSortableColumn="LOADED_COUNT">Loaded Count<p-sortIcon
                                            field="LOADED_COUNT"></p-sortIcon>
                                        <p-columnFilter type="text" field="LOADED_COUNT"
                                            display="menu"></p-columnFilter>
                                    </th>
                                    <th pSortableColumn="TOTAL_COUNT">Total Count<p-sortIcon
                                            field="TOTAL_COUNT"></p-sortIcon>
                                        <p-columnFilter type="text" field="TOTAL_COUNT" display="menu"></p-columnFilter>
                                    </th>
                                    <th pSortableColumn="STATUS">Status<p-sortIcon field="STATUS"></p-sortIcon>
                                        <p-columnFilter type="text" field="STATUS" display="menu"></p-columnFilter>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item>
                                <tr>
                                    <td>{{ item.SETUP_NAME }}</td>
                                    <td>{{ item.CREATED_DATE | date:'medium' }}</td>
                                    <td>{{ item.SETUP_TYPE }}</td>
                                    <td>{{ item.LOADED_COUNT | number }}</td>
                                    <td>{{ item.TOTAL_COUNT | number }}</td>
                                    <td class="tr-table-status">
                                        <span *ngIf="item.STATUS!='Completed'"class="badge bg-primary tr-process">{{ item.STATUS }}</span>
                                        <span *ngIf="item.STATUS=='Completed'" class="badge bg-primary tr-completed">Completed</span>
                                        <!-- <span class="badge bg-primary tr-map">Need To Map Attributes </span> -->

                                    </td>
                                    <td>

                                        <span *ngIf="fileMappingActionItemsList.includes(item.SETUP_TYPE)" class="tr-mapping">
                                            <span *ngIf="item.STATUS!='ERROR' && item.STATUS!='Completed'">
                                                <span *ngIf="item.FIRST_LINE==''  && item.MAPPING_ATTRIBUTE_LIST==''">
                                                    Header map process is going on..
                                                </span>
                                                <!-- <span *ngIf="item.FIRST_LINE!=''   && item.MAPPING_ATTRIBUTE_LIST==''">
                                                    <a href='javascript:;'
                                                        (click)="mappingAction(item,exlargeModal);">Mapping</a>
                                                </span>
                                                <span *ngIf="item.STATUS!='Completed'">
                                                    <a href='javascript:;' (click)="cancelList(item.ID);">Cancel</a>
                                                </span> -->
                                                <span class="d-flex tr-mapping">
                                                    <button *ngIf="item.FIRST_LINE!='' && item.MAPPING_ATTRIBUTE_LIST==''"
                                                    type="button" (click)="mappingAction(item,exlargeModal);"
                                                    class="btn btn-outline-primary mapping-icon"><img
                                                        src="../../../assets/images/mapping.svg"
                                                        alt="mapping-icon"></button>
                                                <button href="javascript:;" (click)="cancelList(item.ID)" type="button" class= "mx-2 btn btn-outline-danger mapping-icon"><i class="bx bx-x"></i></button>
                                                </span>
                                            </span>
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Extra Large Modal -->
    <ng-template #exlargeModal let-modal>
        <div class="tr-input-modal">
            <div class="modal-header">
                <h5 class="modal-title mt-0">Mapping Attribute Item</h5>
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Close click')">
                </button>
            </div>
            <div class="modal-body">
                <app-mapping-attribute-item [inputAttriuteItem]="inputAttriuteItem" [payloadAttribute]="payloadAttribute"
                    [isPopup]="true" (closeMappingAction)="closeMappingAction()"
                    *ngIf="mappingForm"></app-mapping-attribute-item>
                <!-- <app-mapping-attribute [inputAttriuteItem]="inputAttriuteItem" [payloadAttribute]="payloadAttribute" [isPopup]="true" (closeMappingAction)="closeMappingAction()" *ngIf="mappingForm"></app-mapping-attribute> -->
            </div>
        </div>
    </ng-template>