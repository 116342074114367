<div class="row" *ngIf="queryFormShow">
    <div class="col-lg-12">
        <div class="card">
            <ngx-spinner name="querysubmission" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
            <div class="card-body">
                <h4 class="card-title">Query Input</h4>
                <form [formGroup]="queryBuildForm" (ngSubmit)="queryBuildFormSubmit()">
                    <div class="col-lg-12">
                        <div>
                            <label class="form-label">Display Name</label>
                            <input formControlName="setupName" class="form-control" type="text" placeholder="Display Name" [ngClass]="{'is-invalid': submitted && queryForm.setupName.errors}">
                            <div *ngIf="submitted && queryForm.setupName.errors" class="invalid-feedback">
                                <span *ngIf="queryForm.setupName.errors.required">Please provide a valid Display Name.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <label class="form-label">Write a query</label>
                        <div>
                            <textarea formControlName="setupQueryString" class="form-control" rows="5" [ngClass]="{'is-invalid': submitted && queryForm.setupQueryString.errors}"></textarea>
                            <div *ngIf="submitted && queryForm.setupQueryString.errors" class="invalid-feedback">
                                <span *ngIf="queryForm.setupQueryString.errors.required">Please provide a valid Query.</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="submit" class="btn btn-primary w-md">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<br />
<app-mapping-attribute *ngIf="!queryFormShow && inputAttriuteItem.length>0" [inputAttriuteItem]="inputAttriuteItem" [payloadAttribute]="payloadAttribute"></app-mapping-attribute>