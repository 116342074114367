import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";

@Component({
  selector: "app-userlist",
  templateUrl: "./userlist.component.html",
  styleUrls: ["./userlist.component.scss"],
})
export class UserlistComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  userList: any = [];
  rowsPerPage: number = 10;
  totalRecords: number = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private sharingService: SharingService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "User List";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.getUserList();
  }
  getUserList() {
    this.spinner.show("userListFullPageLoader");
    this.userList = [];
    let payloadItem: any = {
      reportType: "get_all_user",
      createdBy: this.sharingService.activeLoggedUsername,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.userList = responseItem.body.Result;
      } else {
        this.errorService.handleException(responseItem.message);
      }
      this.spinner.hide("userListFullPageLoader");
    });
  }
  edit(item: any) {
    this.router.navigate(["/device-mgmt/user/create/" + item.ID]);
  }
}
