<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">My Files</h4>

                <div class="table-responsive">
                    <table class="table mb-0">

                        <thead class="thead-dark">
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Process Type</th>
                                <th>Created At</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of fileList; let i = index">
                                <th scope="row">{{ i+1 }}</th>
                                <td>{{ item.SETUP_NAME }}</td>
                                <td>{{ item.PROCESS_TYPE }}</td>
                                <td>{{ item.CREATED_DATE | date:'medium' }}</td>
                                <td>{{ item.STATUS }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>