<div class="container-fluid">
    <app-page-title [title]="titleOfPage" [breadcrumbItems]="breadCrumbItems"></app-page-title>
</div>


<div class="row">
    <div class="col-xl-8">
        <div class="card">
            <div class="card-body">
                <ngx-spinner name="pageMetList" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
                <div class="table-responsive" *ngIf="metaList.length>0">

                    <p-table #metaListTable [value]="metaList" [paginator]="true" [rows]="rowsPerPage" [showCurrentPageReport]="true" [tableStyle]="{ 'min-width': '50rem' }" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[10, 25, 50]" dataKey="ID">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="PAGE_NAME">Page Name <p-sortIcon field="PAGE_NAME"></p-sortIcon>
                                    <p-columnFilter type="text" field="PAGE_NAME" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="ACTIVE_STATUS">Active <p-sortIcon field="ACTIVE_STATUS"></p-sortIcon>
                                    <p-columnFilter type="text" field="ACTIVE_STATUS" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="MODIFIED_DATE">Update At <p-sortIcon field="MODIFIED_DATE"></p-sortIcon>
                                    <p-columnFilter type="text" field="MODIFIED_DATE" display="menu"></p-columnFilter>
                                </th>
                                <th pSortableColumn="CREATED_BY">Created By <p-sortIcon field="CREATED_BY"></p-sortIcon>
                                    <p-columnFilter type="text" field="CREATED_BY" display="menu"></p-columnFilter>
                                </th>
                                <th>Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr>
                                <td>{{ item.PAGE_NAME }}</td>
                                <td>{{ item.ACTIVE_STATUS=="Y" ? "Active" : "De-Active" }}</td>
                                <td>{{ item.MODIFIED_DATE | date:'mediumDate' }}</td>
                                <td>{{ item.CREATED_BY }}</td>
                                <td><a href='javascript:;' (click)="edit(item);">Edit</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='javascript:;'>View</a></td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4">
        <div class="card">
            <div class="card-body">
                <ngx-spinner name="pageMetCreate" size="large" type="ball-scale-multiple" [fullScreen]="false"></ngx-spinner>
                <h4 class="card-title mb-4">Page Meta Creation</h4>
                <div class="col-12">
                    <form [formGroup]="pageMetaFormCreate" (ngSubmit)="pageMetaFormCreationSubmit()">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-label">Page Name</label>
                                <input formControlName="pageName" class="form-control" type="text" placeholder="Page Name" [ngClass]="{'is-invalid': submitted && pageMetaFormControls.pageName.errors}">
                                <div *ngIf="submitted && pageMetaFormControls.pageName.errors" class="invalid-feedback">
                                    <span *ngIf="pageMetaFormControls.pageName.errors.required">Please provide a valid Page Name.</span>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-lg-12">
                                <button type="submit" class="btn btn-primary w-md">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>