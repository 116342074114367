import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-query-input",
  templateUrl: "./query-input.component.html",
  styleUrls: ["./query-input.component.scss"],
})
export class QueryInputComponent implements OnInit {
  @Input("processType") processType: any;
  queryBuildForm: UntypedFormGroup;
  submitted: boolean = false;
  inputAttriuteItem: any = [];
  payloadAttribute: any = {};
  //   inputAttriuteItem: any = ["Phone", "State", "City", "DeviceId", "Shape", "Address"];
  queryFormShow: boolean = true;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.queryFormCreation();
  }
  queryFormCreation() {
    this.queryBuildForm = this.formBuilder.group({
      reportType: ["create_dt_setup", Validators.required],
      setupName: ["", Validators.required],
      setupType: ["queryBuild"],
      setupQueryString: ["", Validators.required],
      processType: [this.processType],
      userId: [this.sharingService.activeLoggedUsername],
    });
  }

  get queryForm() {
    return this.queryBuildForm.controls;
  }
  queryFormReset() {
    this.queryBuildForm.patchValue({
      setupName: "",
      setupQueryString: "",
    });
  }
  queryBuildFormSubmit() {
    this.submitted = true;
    let payloadItem: any = this.queryBuildForm.value;
    if (this.queryBuildForm.valid) {
      this.spinner.show("querysubmission");
      this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          //   this.getdtSetup(responseItem.body.id);
          this.payloadAttribute["id"] = responseItem.body.id;
          this.spinner.hide("querysubmission");
          this.submitted = false;
          this.toastService.showSuccess("Success", "Successfully Submitted!...");
          this.router.navigate(["device-mgmt/inputs/list"]);
        } else {
          this.errorService.handleException(responseItem.errors);
        }
      });
    }
  }
  getdtSetup(primaryId: any) {
    this.spinner.show("querysubmission");
    let payloadItem: any = {
      reportType: "get_dt_setup",
      id: primaryId,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        if (responseItem.body.Result[0].FIRST_LINE != "") {
          this.payloadAttribute["setup_name"] = responseItem.body.Result[0].SETUP_NAME;
          this.inputAttriuteItem = responseItem.body.Result[0].FIRST_LINE.split("|");
          this.queryFormShow = false;
        } else {
          this.spinner.hide("querysubmission");
          this.errorService.handleException("There is no record.");
        }
      } else {
        this.spinner.hide("querysubmission");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
}
