import { Injectable } from "@angular/core";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor(private toastService: ToastService) {}

  handleException(errorMsg: any = "", errorItem: any = {}) {
    if (errorMsg != "") {
      this.toastService.showError("Error", errorMsg);
    } else if (errorItem) {
      this.toastService.showError("Error", errorItem.error.message || "Server Error, Please try again after some time");
    } else {
      this.toastService.showError("Error", "Server Error, Please try again after some time");
    }
  }
}
