import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "duplicatefind",
})
export class DuplicatefindPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let duplicateText = args[0];
    let isDuplicate = 0;
    if (duplicateText.length > 0) {
      duplicateText.forEach((item: any) => {
        if (item.destination.name == value) isDuplicate = 1;
      });
      if (isDuplicate) return true;
      else return false;
    } else {
      return false;
    }
  }
}
