import { Component, OnInit } from "@angular/core";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-accountscreate",
  templateUrl: "./accountscreate.component.html",
  styleUrls: ["./accountscreate.component.scss"],
})
export class AccountscreateComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  accountFormCreate: UntypedFormGroup;
  submitted: boolean = false;
  editAccountId: number = 0;
  accountList: any = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {
    this.editAccountId = 0;
    if (Object.keys(this.activatedRoute.snapshot.params).length > 0) {
      this.editAccountId = Number(this.activatedRoute.snapshot.params["id"]);
    }
  }

  ngOnInit(): void {
    this.titleOfPage = "Create Account";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    if (this.editAccountId > 0) {
      this.getAccountList();
    } else {
      this.accountFormCreation();
    }
  }
  getAccountList() {
    this.spinner.show("accountListFullPageLoader");
    this.accountList = [];
    let payloadItem: any = {
      reportType: "get_dt_account",
      createdBy: this.sharingService.activeLoggedUsername,
      id: this.editAccountId,
      userId: this.sharingService.activeLoggedUsername,
    };
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.accountList = responseItem.body.Result;
        this.accountFormCreation(this.accountList[0]);
      } else {
        this.errorService.handleException(responseItem.message);
      }
      this.spinner.hide("accountListFullPageLoader");
    });
  }
  accountFormCreationSubmit() {
    this.submitted = true;
    if (this.accountFormCreate.valid) {
      this.spinner.show("accountCreateFullPageLoader");
      this.accountFormCreate.value.isActive = this.accountFormCreate.value.isActive ? "Y" : "N";
      this.httpService.postItem("device-tracking", this.accountFormCreate.value).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          const message = this.accountFormCreate.value.id > 0 ? "Successfully Updated!..." : "Successfully Created!...";
          this.toastService.showSuccess("Success", message);
          this.router.navigate(["/device-mgmt/accounts/list"]);
        } else {
          this.errorService.handleException(responseItem.message);
        }
        this.spinner.hide("accountCreateFullPageLoader");
      });
    }
  }
  accountFormCreation(item: any = {}) {
    let selectedItem: any = {
      accountName: "",
      domainName: "",
      isActive: false,
      id: 0,
      reportType: "create_dt_account",
    };
    if (Object.keys(item).length > 0) {
      selectedItem.reportType = "update_dt_account";
      selectedItem.accountName = item.ACCOUNT_NAME;
      selectedItem.domainName = item.DOMAIN_NAME;
      selectedItem.isActive = item.ACTIVE == "Y" ? true : false;
      selectedItem.id = item.ID;
    }
    this.accountFormCreate = this.formBuilder.group({
      reportType: [selectedItem.reportType, Validators.required],
      id: [selectedItem.id],
      createdBy: [this.sharingService.activeLoggedUsername, Validators.required],
      accountName: [selectedItem.accountName, Validators.required],
      domainName: [selectedItem.domainName, Validators.required],
      isActive: [selectedItem.isActive, Validators.required],
      userId: [this.sharingService.activeLoggedUsername, Validators.required],
    });
  }
  get accountFormControls() {
    return this.accountFormCreate.controls;
  }
}
