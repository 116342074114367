import { MsalService } from "@azure/msal-angular";
import { Injectable, Injector } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
// import { PageaccessPipe } from "@app/devicepages/pipe/pageaccess.pipe";
@Injectable({
  providedIn: "root",
})
export class MicrosoftLoginGuard implements CanActivate {
  constructor(private route: ActivatedRoute, private router: Router, private msalService: MsalService, private sharingService: SharingService, private injector: Injector) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let accountActiveStatus: any = this.msalService.instance.getActiveAccount();
    if (accountActiveStatus == null) {
      this.router.navigate(["/account/login"]);
      return false;
    }
    let accessReturnState = this.pageAccessRules(route.data);
    this.sharingService.activeLoggedUsername = accountActiveStatus.username;
    this.sharingService.activeDisplayname = accountActiveStatus.name;
    if (accessReturnState) {
      return true;
    } else {
      this.router.navigate(["/device-mgmt/access-denied"]);
      return false;
    }
  }
  pageAccessRules(customData: any) {
    let returnItem: boolean = false;
    let userAccessListStorage: any = JSON.parse(localStorage.getItem("userInfo"));
    if (userAccessListStorage) {
      if (customData) {
        if (userAccessListStorage.ACCESS_TYPE == "admin") return true;
        else if (userAccessListStorage.ACCESS_TYPE == "user") {
          let userAccessList: any = [];
          userAccessList = JSON.parse(userAccessListStorage["PAGE_ACCESS"]);
          const index = userAccessList.indexOf(customData.pageAccess);
          if (index !== -1) {
            returnItem = true;
          } else returnItem = false;
        }
        return returnItem;
      }
    }
  }
}
