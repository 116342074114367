import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "@app/devicepages/services/http/http.service";
import { ErrorService } from "@app/devicepages/services/error/error.service";
import { ToastService } from "@app/devicepages/services/toast/toast.service";
import { environment } from "@env/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { SharingService } from "@app/devicepages/services/sharing/sharing.service";
@Component({
  selector: "app-report-create",
  templateUrl: "./report-create.component.html",
  styleUrls: ["./report-create.component.scss"],
})
export class ReportCreateComponent implements OnInit {
  titleOfPage: any = "";
  breadCrumbItems: Array<{}>;
  setupList: any = [];
  reportFormGenerate: UntypedFormGroup;
  submitted: boolean = false;
  processTypeList: any = [];
  selectedSetup: any = {};
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private errorService: ErrorService,
    private sharingService: SharingService
  ) {}

  ngOnInit(): void {
    this.titleOfPage = "Report Creation";
    this.breadCrumbItems = [{ label: "Home" }, { label: this.titleOfPage, active: true }];
    this.reportCreationForm();
    this.getdtSetup();
  }
  //   getProcessType(event: any) {
  //     console.log("event----", event);
  //     if (event == "Shape") {
  //       this.reportFormGenerate.patchValue({
  //         thresholdVal: this.selectedSetup.THRESHOLD,
  //       });
  //     }
  //   }
  getService(event: any) {
    this.processTypeList = [];
    this.selectedSetup = event;
    if (event.SERVICE_OUTPUT != "" && event.SERVICE_OUTPUT != null) {
      let tempItem: any = ([] = JSON.parse(event.SERVICE_OUTPUT));
      tempItem.forEach((element: any) => {
        this.processTypeList = [...this.processTypeList, element];
      });
      this.reportFormGenerate.patchValue({
        setupName: event.SETUP_NAME,
      });
    } else {
      this.toastService.showError("Error", "There is no service!...");
    }
  }
  getdtSetup() {
    this.spinner.show("reportCreationFormLoader");
    let payloadItem: any = {
      reportType: "get_dt_setup",
      statusVal: "completed",
      userId: this.sharingService.activeLoggedUsername,
    };
    // status: "completed",
    this.httpService.postItem("device-tracking", payloadItem).then((responseItem: any) => {
      if (responseItem.statusCode == "200") {
        this.setupList = responseItem.body.Result;
        this.spinner.hide("reportCreationFormLoader");
      } else {
        this.spinner.hide("reportCreationFormLoader");
        this.errorService.handleException(responseItem.message);
      }
    });
  }
  reportCreationForm() {
    this.reportFormGenerate = this.formBuilder.group({
      reportType: ["create_dt_report", Validators.required],
      reportName: ["", Validators.required],
      setupId: [, Validators.required],
      setupName: [""],
      thresholdVal: ["0"],
      processType: [, Validators.required],
      userId: [this.sharingService.activeLoggedUsername],
    });
  }

  reportFormCreationSubmit() {
    if (this.reportFormGenerate.valid) {
      this.spinner.show("reportCreationFormLoader");
      this.httpService.postItem("device-tracking", this.reportFormGenerate.value).then((responseItem: any) => {
        if (responseItem.statusCode == "200") {
          this.toastService.showSuccess("Success", "Successfully Creatd!...");
          this.spinner.hide("reportCreationFormLoader");
          this.router.navigate(["/device-mgmt/report/list"]);
        } else {
          this.spinner.hide("reportCreationFormLoader");
          this.errorService.handleException(responseItem.message);
        }
      });
    }
  }
  get reportFormControl() {
    return this.reportFormGenerate.controls;
  }
}
