import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { Page404Component } from "./extrapages/page404/page404.component";

const routes: Routes = [
  {
    path: "account",
    loadChildren: () => import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "device-mgmt",
    component: LayoutComponent,
    loadChildren: () => import("./devicepages/devicepages.module").then((m) => m.DevicepagesModule),
  },
  // tslint:disable-next-line: max-line-length
  { path: "**", redirectTo: "account/login" },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
// relativeLinkResolution: "legacy",
export class AppRoutingModule {}
